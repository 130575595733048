// src/components/ui/cards/EntityList.js
import React, { useState } from "react";
import EntityCard from "./EntityCard";
import { Row, Col, Input, Select, Empty } from "antd";

const { Search } = Input;
const { Option } = Select;

const EntityList = ({
  entities,
  fields,
  actions,
  onSelect,
  selectedEntities,
  statusField,
  StatusBadgeComponent,
  searchBy = [], // Array of field names to search by
  filters = [], // Array of filter configurations
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValues, setFilterValues] = useState({});

  // Function to handle search input change
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Function to handle filter changes
  const handleFilterChange = (filterName, value) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  // Function to filter entities based on search term and filter values
  const filteredEntities = entities.filter((entity) => {
    const { attributes } = entity;

    // Search filtering
    const matchesSearch = searchBy.length
      ? searchBy.some((field) => {
          const fieldValue = attributes[field];
          if (fieldValue) {
            return fieldValue
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }
          return false;
        })
      : true;

    // Filter criteria
    const matchesFilters = filters.every((filter) => {
      const filterValue = filterValues[filter.name];
      if (!filterValue || filterValue === "all") {
        return true;
      }
      const attributeValue = attributes[filter.name];

      if (filter.name === "brands") {
        // attributeValue is an array of brand objects
        // filterValue is a single brand ID
        return attributeValue.some((brand) => brand.id === filterValue);
      }

      if (Array.isArray(attributeValue)) {
        return attributeValue.includes(filterValue);
      }
      return attributeValue.toString() === filterValue.toString();
    });

    return matchesSearch && matchesFilters;
  });

  return (
    <div>
      {/* Search Input and Filters */}
      <div className="mb-4 flex justify-between items-center">
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: "30%" }}
          allowClear
        />
        {/* Render Filters */}
        <div className="flex space-x-4">
          {filters.map((filter) => (
            <Select
              key={filter.name}
              placeholder={filter.label}
              onChange={(value) => handleFilterChange(filter.name, value)}
              style={{ width: 200 }}
              allowClear
            >
              {filter.options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          ))}
        </div>
      </div>

      {/* Entity Cards */}
      <Row gutter={[16, 16]}>
        {filteredEntities.length > 0 ? (
          filteredEntities.map((entity) => (
            <Col key={entity.id} xs={24} sm={12} md={8} lg={6}>
              <EntityCard
                entity={entity}
                fields={fields}
                actions={actions}
                onSelect={onSelect}
                selected={selectedEntities}
                statusField={statusField}
                StatusBadgeComponent={StatusBadgeComponent}
              />
            </Col>
          ))
        ) : (
          <div className="w-full flex justify-center pt-8">
            <Empty description="No data found" />
          </div>
        )}
      </Row>
    </div>
  );
};

export default EntityList;
