import React, { useState } from "react";
import {
  FaLifeRing,
  FaCalendarAlt,
  FaQuestionCircle,
  FaClipboardCheck,
} from "react-icons/fa";
import SupportForm from "../../components/SupportForm";

const OnboardingSection = ({ onHide }) => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-8 relative">
      {/* Hide Button */}
      <button
        onClick={onHide}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
        aria-label="Hide Onboarding Section"
      >
        &times;
      </button>

      <h2 className="text-3xl font-bold mb-6 text-gray-800">
        Welcome to CrystalCollect
      </h2>
      <p className="text-gray-600 mb-8 leading-relaxed">
        Your data collection solution to support brands and distributors in
        their regulatory compliance. This platform is designed to simplify and
        structure the transmission of the necessary information to your
        customers, brands, and distributors on the French and European market,
        so that they meet regulatory requirements such as the AGEC law and the
        Environmental Display obligations in France (AE-FR) and in the European
        Union (AE-EU).
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Support Link */}
        <div
          className="flex items-center p-4 bg-blue-50 rounded-lg cursor-pointer hover:shadow-lg transition-shadow"
          onClick={openSupportModal}
        >
          <FaLifeRing className="text-blue-500 text-4xl mr-4" />
          <div>
            <h3 className="text-l font-semibold text-gray-800 mb-1">
              Need Help?
            </h3>
            <span className="text-blue-500 hover:underline">
              Visit our support center
            </span>
          </div>
        </div>
        {/* Calendly Appointment Booking */}
        <div className="flex items-center p-4 bg-green-50 rounded-lg hover:shadow-lg transition-shadow">
          <FaCalendarAlt className="text-green-500 text-4xl mr-4" />
          <div>
            <h3 className="text-l font-semibold text-gray-800 mb-1">
              Book a Support Appointment
            </h3>
            <a
              href="https://calendly.com/scariou/crystalchain?month=2024-10"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-500 hover:underline"
            >
              Schedule a meeting
            </a>
          </div>
        </div>

        {/* FAQ Link */}
        <div className="flex items-center p-4 bg-yellow-50 rounded-lg hover:shadow-lg transition-shadow">
          <FaQuestionCircle className="text-yellow-500 text-4xl mr-4" />
          <div>
            <h3 className="text-l font-semibold text-gray-800 mb-1">
              Frequently Asked Questions and Documentation
            </h3>
            <a
              href="https://crystalchain.notion.site/FAQ-CrystalCollect-9ed1840c1eda4868afb2fe2d2dbbb58d"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-500 hover:underline"
            >
              Read our FAQs
            </a>
          </div>
        </div>

        {/* PEF Validator Tool Link */}
        <div className="flex items-center p-4 bg-purple-50 rounded-lg hover:shadow-lg transition-shadow">
          <FaClipboardCheck className="text-purple-500 text-4xl mr-4" />
          <div>
            <h3 className="text-l font-semibold text-gray-800 mb-1">
              PEF Validator Tool
            </h3>
            <a
              href="https://pef.crystalchain.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-500 hover:underline"
            >
              Validate your PEF data
            </a>
          </div>
        </div>
      </div>

      {/* Support Modal */}
      {isSupportModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6">
            <SupportForm onClose={closeSupportModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingSection;
