import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { getBrandSuppliers, getBrandProducts } from '../../services/brandService';
import KPI from '../../components/ui/kpis/KPI';
import { FaTruck, FaBox, FaCheckCircle } from 'react-icons/fa';

const BrandDashboard = () => {
  const { user } = useContext(AuthContext);
  const [suppliersCount, setSuppliersCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [completedProductsPercentage, setCompletedProductsPercentage] = useState(0);

  useEffect(() => {
    fetchDashboardData();
  }, [user.id]);

  const fetchDashboardData = async () => {
    try {
      // Fetch suppliers
      // TOFIX: On backend
      const suppliersResponse = await getBrandSuppliers(user.ud);
      const suppliersData = suppliersResponse.data.data;
      setSuppliersCount(suppliersData.length);

      // Fetch products
      const productsResponse = await getBrandProducts(user.id);
      const productsData = productsResponse.data.data;
      setProductsCount(productsData.length);

      // Calculate completed products percentage
      const completedProducts = productsData.filter(
        (product) => product.attributes.is_completed
      );
      const completedPercentage =
        productsData.length > 0
          ? (completedProducts.length / productsData.length) * 100
          : 0;
      setCompletedProductsPercentage(completedPercentage.toFixed(2));
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Brand Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <KPI
          title="Total Suppliers"
          value={suppliersCount}
          icon={<FaTruck className="h-6 w-6 text-green-500" />}
        />
        <KPI
          title="Total Products"
          value={productsCount}
          icon={<FaBox className="h-6 w-6 text-yellow-500" />}
        />
        <KPI
          title="Completed Products (%)"
          value={`${completedProductsPercentage}%`}
          icon={<FaCheckCircle className="h-6 w-6 text-indigo-500" />}
        />
        {/* Add more KPIs as needed */}
      </div>
    </div>
  );
};

export default BrandDashboard;
