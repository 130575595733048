import api from "./api";

export const login = (email, password) => {
  return api
    .post("/auth/sign_in", { user: { email, password } })
    .then((response) => {
      // Extract token from headers
      const token = response.headers["authorization"].split(" ")[1]; // Authorization header: "Bearer <token>"
      return { token, user: response.data.data.user };
    });
};

export const changePassword = ({ password, password_confirmation, reset_password_token = null }) => {
  return api.put("/auth/password", {
    user: { password, password_confirmation, reset_password_token },
  });
};

export const requestResetPassword = (email) => {
  return api.post("/auth/password", { user: { email } });
};

export const logout = () => {
  return api.delete("/auth/sign_out");
};
