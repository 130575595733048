// src/components/ui/cards/InfoCard.js
import React from 'react';
import { Card } from 'antd';

const InfoCard = ({ title, fields, data, actions }) => {
  return (
    <Card title={title} actions={actions} className="mb-6">
      {fields.map((field) => {
        const value = data[field.name];
        if (field.render) {
          return (
            <p key={field.name} className="mb-2">
              <strong>{field.label}:</strong> {field.render(value, data)}
            </p>
          );
        }
        return (
          <p key={field.name} className="mb-2">
            <strong>{field.label}:</strong> {value}
          </p>
        );
      })}
    </Card>
  );
};

export default InfoCard;
