import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const PrivateRoute = ({ children, roles }) => {
  const { user, isLoading, authToken } = useContext(AuthContext);

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true; // No token means expired

    try {
      const payloadBase64 = token.split(".")[1];
      const decodedPayload = atob(payloadBase64);
      const payload = JSON.parse(decodedPayload);
      const exp = payload.exp;

      // Current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Compare expiration time with current time
      return exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // Assume expired on error
    }
  };

  if (isLoading) {
    // Still loading, return null
    return null;
  }

  if (!user || isTokenExpired(authToken)) {
    // Not logged in or token expired, redirect to login
    return <Navigate to="/login" replace />;
  }

  if (roles && !roles.includes(user.role)) {
    // Logged in but doesn't have the required role
    return <Navigate to="/unauthorized" replace />;
  }

  // Authorized, render the child components
  return children;
};

export default PrivateRoute;
