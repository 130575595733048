// src/components/ui/datagrid/DataGrid.js
import React, { useState, useMemo } from "react";
import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const DataGrid = ({ columns, data, onRowClick }) => {
  const [searchText, setSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });
  };

  // Filtered Data based on search text
  const filteredData = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data.filter((row) =>
      Object.values(row).some(
        (cell) =>
          typeof cell === "string" &&
          cell.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  return (
    <div>
      {/* Search Bar */}
      <div className="flex justify-end mb-2">
        <Input
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200 }}
          prefix={<SearchOutlined />}
          allowClear
        />
      </div>

      {/* Ant Design Table */}
      <Table
        columns={columns}
        dataSource={filteredData}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            if (onRowClick) onRowClick(record);
          },
        })}
      />
    </div>
  );
};

export default DataGrid;
