import externalApi from "./externalApi";

export const validateDataPEF = (data) => {
  return externalApi
    .post("/validate", { data })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error("Failed to validate data");
    });
};
