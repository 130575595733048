// src/components/settings/Settings.js
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateUser } from "../../services/userService";
import { Form, Input, Button, message } from "antd";

const Settings = () => {
  const { user, login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // Form instance
  const [form] = Form.useForm();

  useEffect(() => {
    // Set initial form values
    form.setFieldsValue({
      email: user.email,
      name: user.name,
      first_name: user.first_name,
    });
  }, [user, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Update user data on the server
      const response = await updateUser(user.id, values);

      // Update user data in AuthContext and localStorage
      login(localStorage.getItem("authToken"), response.data);

      message.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile", error);
      message.error("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Account Settings</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          email: user.email,
          name: user.name,
          first_name: user.first_name,
        }}
      >
        {/* Email Field */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input disabled />
        </Form.Item>

        {/* Name Field */}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>

        {/* First Name Field */}
        <Form.Item
          label="First Name"
          name="first_name"
          rules={[{ required: true, message: "First name is required" }]}
        >
          <Input />
        </Form.Item>

        {/* Password Fields */}
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            { min: 6, message: "Password must be at least 6 characters" },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="password_confirmation"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: form.getFieldValue("password") ? true : false,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue("password") === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {loading ? "Updating..." : "Update Profile"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Settings;
