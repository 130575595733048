import React from 'react';

const FormInput = ({ label, register, errors, name, ...rest }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">{label}</label>
      <input
        {...register(name)}
        {...rest}
        className="w-full px-3 py-2 border rounded"
      />
      {errors[name] && (
        <p className="text-red-500 text-xs italic">{errors[name].message}</p>
      )}
    </div>
  );
};

export default FormInput;
