// src/components/ActionButtons.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ActionButtons = ({ onEdit, onDelete }) => {
  return (
    <div className="flex space-x-2">
      <button
        onClick={onEdit}
        className="text-blue-500 hover:text-blue-700 focus:outline-none"
        aria-label="Edit"
        title="Edit"
      >
        <FaEdit />
      </button>
      <button
        onClick={onDelete}
        className="text-red-500 hover:text-red-700 focus:outline-none"
        aria-label="Delete"
        title="Delete"
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default ActionButtons;
