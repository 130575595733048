import api from './api';

export const acceptInvitation = (token, password, passwordConfirmation) => {
  return api.post('/suppliers/accept_invitation', {
    invitation: {
      token,
      password,
      password_confirmation: passwordConfirmation,
    },
  });
};

export const createSupplier = (supplierData) => {
  return api.post('/suppliers', { supplier: supplierData });
};

export const getSuppliers = () => {
  return api.get('/suppliers');
};

export const getSupplier = (supplierId) => {
  return api.get(`/suppliers/${supplierId}`);
};

export const updateSupplier = (supplierId, supplierData) => {
  return api.put(`/suppliers/${supplierId}`, { supplier: supplierData });
};

export const deleteSupplier = (supplierId) => {
  return api.delete(`/suppliers/${supplierId}`);
};


export const getSupplierBrands = () => {
  return api.get('/suppliers/brands');
};

export const getSupplierProducts = (supplierId) => {
  return api.get(`/suppliers/${supplierId}/products`);
};
