// src/components/admin/ManageBrands.js
import React, { useState, useEffect } from "react";
import {
  getBrands,
  createBrand,
  updateBrand,
  deleteBrand,
} from "../../services/brandService";
import { getFormTemplates } from "../../services/formTemplateService";
import EntityList from "../../components/ui/cards/EntityList";
import EntityForm from "../../components/ui/form/EntityForm";
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal, message, Checkbox } from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";

const ManageBrands = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [editingBrand, setEditingBrand] = useState(null);
  const [formTemplates, setFormTemplates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBrands();
    fetchFormTemplates();
  }, []);

  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brands", error);
        message.error("Failed to fetch brands.");
      });
  };

  const fetchFormTemplates = () => {
    getFormTemplates()
      .then((response) => {
        setFormTemplates(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching form templates", error);
        message.error("Failed to fetch form templates.");
      });
  };

  const formFields = [
    { label: "Name", name: "name", type: "text", required: true },
    { label: "Address", name: "address", type: "text", required: true },
    { label: "Country", name: "country", type: "text", required: true },
    {
      name: "license_types",
      label: "License Types",
      type: "multi-select",
      options: formTemplates.map((template) => ({
        value: template.attributes.code,
        label: template.attributes.name,
      })),
      required: true,
    },
  ];

  useEffect(() => {
    if (editingBrand) {
      const initialValues = {};
      formFields.forEach((field) => {
        initialValues[field.name] = editingBrand.attributes[field.name];
      });
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [editingBrand, form, formFields]);

  const handleFormSubmit = (values) => {
    if (editingBrand) {
      updateBrand(editingBrand.id, values)
        .then(() => {
          message.success("Brand updated successfully");
          resetForm();
          fetchBrands();
        })
        .catch((error) => {
          console.error("Error updating brand", error);
          message.error("Failed to update brand");
        });
    } else {
      createBrand(values)
        .then(() => {
          message.success("Brand created successfully");
          resetForm();
          fetchBrands();
        })
        .catch((error) => {
          console.error("Error creating brand", error);
          message.error("Failed to create brand");
        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setEditingBrand(null);
    setIsModalOpen(false);
  };

  const openModalForEditing = (brand) => {
    setEditingBrand(brand);
    setIsModalOpen(true);
  };

  const handleEdit = (brand) => {
    openModalForEditing(brand);
  };

  const handleSelect = (brandId) => {
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brandId)
        ? prevSelected.filter((id) => id !== brandId)
        : [...prevSelected, brandId]
    );
  };

  const handleSelectAll = () => {
    if (selectedBrands.length === brands.length) {
      setSelectedBrands([]);
    } else {
      setSelectedBrands(brands.map((brand) => brand.id));
    }
  };

  const handleViewDetails = (brandId) => {
    navigate(`/admin/brands/${brandId}`);
  };

  const handleDelete = (brandId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this brand?",
      onOk: () => {
        deleteBrand(brandId)
          .then(() => {
            message.success("Brand deleted successfully");
            fetchBrands();
          })
          .catch((error) => {
            console.error("Error deleting brand", error);
            message.error("Failed to delete brand");
          });
      },
    });
  };

  const entityFields = [
    { name: "address", label: "Address" },
    { name: "country", label: "Country" },
    {
      name: "license_types",
      label: "License Types",
      render: (value, attributes) => {
        const licenseTypes = attributes.license_types || [];
        return licenseTypes.join(", ");
      },
    },
  ];

  const actions = [
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: "view",
      label: "View",
      icon: <EyeOutlined />,
      onClick: (entity) => handleViewDetails(entity.id),
    },
    {
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: (entity) => handleDelete(entity.id),
    },
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Brands</h2>
        <Button
          onClick={() => setIsModalOpen(true)}
          type="primary"
          icon={<FaPlus />}
        >
          Add New Brand
        </Button>
      </div>

      <div className="flex justify-between items-center mb-4">
        {selectedBrands.length > 0 && (
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete selected brands?",
                onOk: () => {
                  selectedBrands.forEach((id) => handleDelete(id));
                  setSelectedBrands([]);
                },
              });
            }}
          >
            Delete Selected
          </Button>
        )}
      </div>

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFormSubmit}
        editingEntity={editingBrand}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={formFields}
        entityName="Brand"
        form={form}
      />

      {/* Select All Checkbox */}
      <Checkbox
        checked={selectedBrands.length === brands.length}
        onChange={handleSelectAll}
        style={{ marginBottom: 16 }}
      >
        Select All
      </Checkbox>

      {/* Entity List */}
      <EntityList
        entities={brands}
        fields={entityFields}
        actions={actions}
        onSelect={handleSelect}
        selectedEntities={selectedBrands}
        searchBy={["name", "country"]}
        filters={[
          {
            name: "country",
            label: "Country",
            options: [
              ...Array.from(
                new Set(brands.map((brand) => brand.attributes.country))
              ).map((country) => ({
                value: country,
                label: country,
              })),
            ],
          },
        ]}
      />
    </div>
  );
};

export default ManageBrands;
