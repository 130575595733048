import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token.trim()}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized request');
      // Clear authentication data
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');

      // Optionally, display a message to the user
      alert('Your session has expired. Please log in again.');

      // Redirect to the login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);


export default api;

