import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { inviteSupplier } from '../../services/brandService';
import { AuthContext } from '../../contexts/AuthContext';
import FormInput from '../../components/ui/form/FormInput';

const AddSupplier = () => {
  const { user } = useContext(AuthContext);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    inviteSupplier(user.brand_id, data)
      .then(() => {
        alert('Invitation sent to supplier');
      })
      .catch((error) => {
        console.error('Error inviting supplier', error);
      });
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Invite Supplier</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Email"
          name="email"
          type="email"
          register={register}
          errors={errors}
          required
        />
        <FormInput
          label="Company Name"
          name="company_name"
          register={register}
          errors={errors}
          required
        />
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
          Send Invitation
        </button>
      </form>
    </div>
  );
};

export default AddSupplier;
