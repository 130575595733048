import api from './api';

export const createBrand = (brandData) => {
  return api.post('/brands', { brand: brandData });
};

export const getBrands = () => {
  return api.get('/brands');
};

export const getBrand = (brandId) => {
  return api.get(`/brands/${brandId}`);
}

export const updateBrand = (brandId, brandData) => {
  return api.put(`/brands/${brandId}`, { brand: brandData });
};

export const deleteBrand = (brandId) => {
  return api.delete(`/brands/${brandId}`);
};

export const inviteSupplier = (brandId, supplierData) => {
  return api.post(`/brands/${brandId}/suppliers/invite`, { supplier: supplierData });
};

export const getBrandSuppliers = (brandId) => {
  return api.get(`/brands/${brandId}/suppliers`);
};

export const getBrandProducts = (brandId) => {
  return api.get(`/brands/${brandId}/products`);
};

