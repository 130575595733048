import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");

    // Check if storedUser is null, 'undefined', or an invalid JSON string
    if (!storedUser || storedUser === 'undefined') {
      return null;
    }

    try {
      return JSON.parse(storedUser);
    } catch (error) {
      console.error("Error parsing stored user:", error);
      // Optionally, remove the corrupted entry from localStorage
      localStorage.removeItem("user");
      return null;
    }
  });

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true; // No token means expired

    try {
      const payloadBase64 = token.split(".")[1];
      const decodedPayload = atob(payloadBase64);
      const payload = JSON.parse(decodedPayload);
      const exp = payload.exp;

      // Current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Compare expiration time with current time
      return exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // Assume expired on error
    }
  };

  const login = (token, userData, navigateTo = true) => {
    if (!userData) {
      console.error("User data is undefined");
      return;
    }

    localStorage.setItem("authToken", token);
    localStorage.setItem("user", JSON.stringify(userData));
    setAuthToken(token);
    setUser(userData);

    const rolePath = userData.role.split("_")[0];
    if (navigateTo) navigate(`/${rolePath}`);
  };

  const logout = () => {
    localStorage.clear();
    setAuthToken(null);
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
