import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createProduct } from '../../services/productService';
import { getBrandSuppliers } from '../../services/brandService';
import { AuthContext } from '../../contexts/AuthContext';
import FormInput from '../../components/ui/form/FormInput';

const AddProduct = () => {
  const { user } = useContext(AuthContext);
  const [suppliers, setSuppliers] = useState([]);
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    getBrandSuppliers(user.brand_id)
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching suppliers', error);
      });
  }, [user.brand_id]);

  const onSubmit = (data) => {
    createProduct(user.brand_id, data)
      .then(() => {
        alert('Product added successfully');
      })
      .catch((error) => {
        console.error('Error adding product', error);
      });
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Add Product</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Product Name"
          name="name"
          register={register}
          errors={errors}
          required
        />
        <FormInput
          label="Reference"
          name="reference"
          register={register}
          errors={errors}
          required
        />
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Supplier</label>
          <select {...register('supplier_id')} className="w-full px-3 py-2 border rounded">
            {suppliers.map((supplier) => (
              <option value={supplier.id} key={supplier.id}>
                {supplier.company_name}
              </option>
            ))}
          </select>
          {errors.supplier_id && (
            <p className="text-red-500 text-xs italic">{errors.supplier_id.message}</p>
          )}
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
