// src/components/common/KPI.js
import React from 'react';
import PropTypes from 'prop-types';

const KPI = ({ title, value, icon, color }) => {
  return (
    <div className={`bg-white shadow rounded-lg p-5 flex items-center ${color}`}>
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div className="ml-5 w-0 flex-1">
        <dl>
          <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
          <dd>
            <div className="text-2xl font-bold text-gray-900">{value}</div>
          </dd>
        </dl>
      </div>
    </div>
  );
};

KPI.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
  color: PropTypes.string,
};

KPI.defaultProps = {
  icon: null,
  color: 'border-l-4 border-gray-300',
};

export default KPI;
