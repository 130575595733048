import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center bg-blue-500 text-white px-4 py-2 rounded-t"
      >
        <span className="font-semibold">{title}</span>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isOpen && <div className="p-4 bg-gray-50 rounded-b">{children}</div>}
    </div>
  );
};


const ProductDetails = ({ productData }) => {
  return (
    <div className="bg-white p-6 rounded shadow mb-6">
      <h2 className="text-2xl text-xl font-semibold mb-4">Product Details</h2>
      <CollapsibleSection title="Basic Information">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="text-sm">Product Name</label>
            <p className="font-semibold">{productData["Product Name"]}</p>
          </div>
          <div>
            <label className="text-sm">Brand</label>
            <p className="font-semibold">{productData["Brand"]}</p>
          </div>
          <div>
            <label className="text-sm">Reference Brand</label>
            <p className="font-semibold">{productData["Reference Brand"]}</p>
          </div>
          <div>
            <label className="text-sm">Reference Supplier</label>
            <p className="font-semibold">{productData["Reference Supplier"]}</p>
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title="Additional Information" >
        <div>
          <label className="text-sm">Supplier</label>
          <p className="font-semibold">{productData["Supplier"]}</p>
        </div>
        <div>
          <label className="text-sm">Type</label>
          <p className="font-semibold">{productData["Type"]}</p>
        </div>
        <div>
          <label className="text-sm">Status</label>
          <p className="font-semibold">{productData["Status"]}</p>
        </div>
        <div>
            <label className="text-sm">Created At</label>
            <p className="font-semibold">{productData["Created At"]}</p>
          </div>
          <div>
            <label className="text-sm">Updated At</label>
            <p className="font-semibold">{productData["Updated At"]}</p>
          </div>
      </CollapsibleSection>
    </div>
  );
};

export default ProductDetails;
