// src/components/auth/ResetPassword.js

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { changePassword } from "../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordInput from "../ui/form/PasswordInput";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError: setFormError,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Get token from query params
  const query = new URLSearchParams(useLocation().search);
  const resetToken = query.get("token");

  const onSubmit = async (data) => {
    try {
      setError(null);
      if (data.password !== data.password_confirmation) {
        setFormError("password_confirmation", {
          type: "manual",
          message: "Passwords do not match",
        });
        return;
      }

      await changePassword({
        password: data.password,
        password_confirmation: data.password_confirmation,
        reset_password_token: resetToken,
      });

      setSuccess(true);
    } catch (error) {
      setError("Error resetting password. Please try again.");
    }
  };

  if (success) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-md w-full p-6 bg-white shadow-md rounded">
          <h2 className="text-2xl font-bold mb-4">Password Reset Successful</h2>
          <p className="mb-6">
            Your password has been reset successfully. You can now log in with
            your new password.
          </p>
          <button
            onClick={() => navigate("/login")}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen justify-center bg-gray-100">
      <div className="mb-12 pb-2">
        <h1 className="text-5xl font-bold">
          Crystal
          <span className="bg-blue-600 text-white px-2">Collect</span>
        </h1>
      </div>
      <div className="max-w-md w-full p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-bold mb-4">Reset Your Password</h2>
        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <PasswordInput
            name="password"
            label="New Password"
            register={register}
            validation={{
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
                message:
                  "Password must contain uppercase, lowercase, number, and special character",
              },
            }}
            error={errors.password}
            show={showPassword}
            toggle={() => setShowPassword(!showPassword)}
            placeholder="Enter new password"
          />

          <PasswordInput
            name="password_confirmation"
            label="Confirm New Password"
            register={register}
            validation={{
              required: "Please confirm your password",
              validate: (value) =>
                value === watch("password") || "Passwords do not match",
            }}
            error={errors.password_confirmation}
            show={showConfirmPassword}
            toggle={() => setShowConfirmPassword(!showConfirmPassword)}
            placeholder="Confirm new password"
          />

          <button
            type="submit"
            className="w-full py-2 px-4 mt-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
