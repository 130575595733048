import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  login as loginService,
  changePassword,
  requestResetPassword,
} from "../../services/authService";
import { AuthContext } from "../../contexts/AuthContext";
import { FaEnvelope } from "react-icons/fa";
import PasswordInput from "../ui/form/PasswordInput";

const side_illustration = require("../../assets/images/cc/side-illustration.png");

const Login = () => {
  const { login } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setFormError,
    watch,
  } = useForm();

  const onEmailSubmit = (data) => {
    setEmail(data.email);
    setStep(2);
  };

  const onPasswordSubmit = async (data) => {
    try {
      setError(null);
      const response = await loginService(email, data.password);

      if (response.user.force_password_change) {
        const token = response.token;
        const user = response.user;
        login(token, user, false);
        setCurrentPassword(data.password);
        setStep(3);
      } else {
        login(response.token, response.user);
      }
    } catch (error) {
      setError("Invalid email or password");
    }
  };

  const onPasswordChangeSubmit = async (data) => {
    try {
      setError(null);
      if (data.newPassword !== data.confirmPassword) {
        setFormError("confirmPassword", {
          type: "manual",
          message: "Passwords do not match",
        });
        return;
      }

      const response = await changePassword({
        password: data.newPassword,
        password_confirmation: data.confirmPassword,
      });

      if (response.status === 200) {
        const loginResponse = await loginService(email, data.newPassword);
        login(loginResponse.token, loginResponse.user);
      }
    } catch (error) {
      setError("Error changing password. Please try again.");
    }
  };

  const onResetRequestSubmit = async (data) => {
    try {
      setError(null);
      await requestResetPassword(data.email);
      setEmail(data.email);
      setStep(5); // Proceed to confirmation step
    } catch (error) {
      setError("Error sending password reset email. Please try again.");
    }
  };

  const passwordValidation = {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters",
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  };

  return (
    <div className="flex min-h-screen">
      {/* Left Side */}
      <div className="w-full md:w-3/5 flex flex-col items-center justify-start bg-white p-12">
        {/* App Logo */}
        <div className="mb-12 pb-4 pt-8">
          <h1 className="text-5xl font-bold">
            Crystal
            <span className="bg-blue-600 text-white px-2">Collect</span>
          </h1>
        </div>

        <div className="max-w-md w-full">
          <h2 className="text-2xl font-extrabold text-gray-900 text-left mb-6 text-center">
            Welcome to the Data Collector app!
          </h2>

          {error && (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          {/* Step 1: Enter Email */}
          {step === 1 && (
            <form onSubmit={handleSubmit(onEmailSubmit)} noValidate>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Email address
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <FaEnvelope className="h-5 w-5 text-gray-400" />
                  </span>
                  <input
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    placeholder="Enter your email"
                  />
                </div>
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Next
              </button>
            </form>
          )}

          {/* Step 2: Enter Password */}
          {step === 2 && (
            <form onSubmit={handleSubmit(onPasswordSubmit)} noValidate>
              <PasswordInput
                name="password"
                label="Password"
                register={register}
                validation={{ required: "Password is required" }}
                error={errors.password}
                show={showPassword}
                toggle={() => setShowPassword(!showPassword)}
                placeholder="Enter your password"
              />

              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="text-sm text-blue-600 hover:text-blue-500 focus:outline-none"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Sign in
                </button>
              </div>

              {/* Forgot Password Link */}
              <div className="mt-4 text-center">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(4);
                  }}
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </a>
              </div>
            </form>
          )}

          {/* Step 3: Change Password on First Login */}
          {step === 3 && (
            <form onSubmit={handleSubmit(onPasswordChangeSubmit)} noValidate>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">
                  Change Your Password
                </h3>
                <p className="text-gray-600 text-sm">
                  For security reasons, you need to change your password on
                  first login.
                </p>
              </div>

              <PasswordInput
                name="newPassword"
                label="New Password"
                register={register}
                validation={passwordValidation}
                error={errors.newPassword}
                show={showNewPassword}
                toggle={() => setShowNewPassword(!showNewPassword)}
                placeholder="Enter new password"
              />

              <PasswordInput
                name="confirmPassword"
                label="Confirm New Password"
                register={register}
                validation={{
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === watch("newPassword") || "Passwords do not match",
                }}
                error={errors.confirmPassword}
                show={showConfirmPassword}
                toggle={() => setShowConfirmPassword(!showConfirmPassword)}
                placeholder="Confirm new password"
              />

              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Change Password and Continue
                </button>
              </div>
            </form>
          )}

          {/* Step 4: Password Reset Request */}
          {step === 4 && (
            <form onSubmit={handleSubmit(onResetRequestSubmit)} noValidate>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Enter your email address to reset your password
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <FaEnvelope className="h-5 w-5 text-gray-400" />
                  </span>
                  <input
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    placeholder="Enter your email"
                  />
                </div>
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={() => setStep(2)}
                  className="text-sm text-blue-600 hover:text-blue-500 focus:outline-none"
                >
                  Back to Login
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Send Reset Email
                </button>
              </div>
            </form>
          )}

          {/* Step 5: Confirmation After Reset Email Sent */}
          {step === 5 && (
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4">Check Your Email</h3>
              <p className="text-gray-600 mb-6">
                We have sent a password reset link to {email}. Please check your
                inbox.
              </p>
              <button
                onClick={() => setStep(2)}
                className="text-sm text-blue-600 hover:text-blue-500"
              >
                Back to Login
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Right Side */}
      <div className="hidden md:block md:w-3/5">
        <img
          src={side_illustration}
          alt="Side Illustration"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Login;
