import api from './api';

export const createFormTemplate = (formTemplateData) => {
  return api.post('/form_templates', { form_template: formTemplateData });
};

export const getFormTemplates = () => {
  return api.get('/form_templates');
};

export const getFormTemplate = (formTemplateId) => {
  return api.get(`/form_templates/${formTemplateId}`);
};

export const updateFormTemplate = (formTemplateId, formTemplateData) => {
  return api.put(`/form_templates/${formTemplateId}`, { form_template: formTemplateData });
};

export const deleteFormTemplate = (formTemplateId) => {
  return api.delete(`/form_templates/${formTemplateId}`);
};
