import React, { useState, useEffect } from "react";
import {
  PlusCircle,
  MinusCircle,
  ChevronDown,
  ChevronRight,
  Save,
  Copy,
  DraftingCompass,
} from "lucide-react";

import SearchableSelect from "../form/SearchableSelect";
import { validateDataPEF } from "../../../services/pefValidator";
//import { message } from "react-messageify";
// message form antd
import { message } from "antd";

const createDefaultData = (schema) => {
  if (!schema || !schema.properties) {
    if (schema?.type === "string") return "";
    if (schema?.type === "number" || schema?.type?.toLowerCase() === "float")
      return 0;
    if (schema?.type === "boolean") return false;
    if (schema?.type === "array") return [];
    return null;
  }

  const defaultData = {};
  Object.entries(schema.properties).forEach(([key, fieldSchema]) => {
    if (fieldSchema.type?.toLowerCase() === "array") {
      defaultData[key] = fieldSchema.items
        ? [createDefaultData(fieldSchema.items)]
        : [];
    } else if (fieldSchema.type?.toLowerCase() === "object") {
      defaultData[key] = createDefaultData(fieldSchema);
    } else if (fieldSchema.type?.toLowerCase() === "boolean") {
      defaultData[key] = false;
    } else if (
      fieldSchema.type?.toLowerCase() === "number" ||
      fieldSchema.type?.toLowerCase() === "float"
    ) {
      defaultData[key] = 0;
    } else {
      defaultData[key] = "";
    }
  });

  return defaultData;
};

const SchemaBasedDataGrid = ({
  inputSchema,
  initialData = null,
  uiSchema = {},
  onSubmit,
  onDraft,
  mode = "edit",
}) => {
  const [formData, setFormData] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [schema, setSchema] = useState(null);
  //const [errors, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorSections, setErrorSections] = useState(new Set());

  useEffect(() => {
    try {
      // Ensure we have a proper schema object
      const processedSchema =
        typeof schema === "string" ? JSON.parse(inputSchema) : inputSchema;

      setSchema(processedSchema);

      // Check if data is not {}
      if (initialData && Object.keys(initialData).length === 0) {
        initialData = null;
      }

      // Process initial data if provided
      const processedData = initialData
        ? typeof initialData === "string"
          ? JSON.parse(initialData)
          : initialData
        : createDefaultData(processedSchema);

      setFormData(processedData);

      setExpandedSections({ "-": true });
    } catch (err) {
      console.error("Error processing schema or initial data:", err);
      setErrors(err.message);
    }
  }, [inputSchema, initialData]);

  const toggleExpand = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const renderField = (fieldSchema, value, onChange, path) => {
    const hasError = errors[path];
    const isRequired = fieldSchema.required;
    const widgetType = getWidgetType(fieldSchema, path);

    if (!fieldSchema) return null;

    if (fieldSchema.type === "boolean" || widgetType === "checkbox") {
      return (
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={!!value}
              onChange={(e) => {
                onChange(e.target.checked);

                // If this is hasAccessories and it's being unchecked,
                // reset the Accessories array
                if (path.includes("hasAccessories") && !e.target.checked) {
                  const partsPath = path.split(".partInfo")[0];
                  const accessoiresPath = `${partsPath}.Accessoires`;
                  handleInputChange(accessoiresPath, []);
                }
              }}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">{fieldSchema.title}</span>
          </label>
          {hasError && <p className="mt-1 text-sm text-red-500">{hasError}</p>}
        </div>
      );
    }

    if (fieldSchema.enum) {
      if (widgetType === "radio") {
        return (
          <div className="space-y-2">
            {fieldSchema.enum.map((option) => (
              <label key={option} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={path}
                  value={option}
                  checked={value === option}
                  onChange={(e) => onChange(e.target.value)}
                  className="text-blue-600"
                />
                <span>{option}</span>
              </label>
            ))}
            {hasError && (
              <p className="mt-1 text-sm text-red-500">{hasError}</p>
            )}
          </div>
        );
      }

      return (
        <div>
          <SearchableSelect
            options={fieldSchema.enum}
            value={value}
            onChange={onChange}
            placeholder={`Select${isRequired ? " *" : ""}...`}
            hasError={hasError}
            isRequired={isRequired}
          />
          {hasError && <p className="mt-1 text-sm text-red-500">{hasError}</p>}
        </div>
      );
    }

    const inputType =
      fieldSchema.type?.toLowerCase() === "float" ? "number" : "text";
    return (
      <div>
        <input
          type={inputType}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          className={`w-full border rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent
            ${hasError ? "border-red-500 bg-red-50" : "border-gray-300"}`}
          step={inputType === "number" ? "0.01" : undefined}
          placeholder={isRequired ? "Required" : ""}
        />
        {hasError && <p className="mt-1 text-sm text-red-500">{hasError}</p>}
      </div>
    );
  };

  const handleInputChange = (path, value) => {
    setFormData((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));
      const parts = path.split(".");
      let current = newData;

      // For all parts except the last one
      for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];

        if (part.includes("[")) {
          const [arrayName, indexPart] = part.split("[");
          const index = parseInt(indexPart.replace("]", ""));
          if (!current[arrayName]) current[arrayName] = [];
          if (!current[arrayName][index]) current[arrayName][index] = {};
          current = current[arrayName][index];
        } else {
          if (!current[part]) current[part] = {};
          current = current[part];
        }
      }

      // Handle the last part
      const lastPart = parts[parts.length - 1];
      if (lastPart.includes("[")) {
        const [arrayName, indexPart] = lastPart.split("[");
        const index = parseInt(indexPart.replace("]", ""));
        if (!current[arrayName]) current[arrayName] = [];
        current[arrayName][index] = value;
      } else {
        current[lastPart] = value;

        // Handle boolean field changes that might affect conditional displays
        if (typeof value === "boolean") {
          // Get the base path to find the parent object containing the arrays
          const basePathParts = path.split(".").slice(0, -2); // Remove field name and its parent object
          let parentObject = newData;

          // Navigate to the correct parent object
          for (const part of basePathParts) {
            if (part.includes("[")) {
              const [arrayName, indexStr] = part.split("[");
              const index = parseInt(indexStr.replace("]", ""));
              parentObject = parentObject[arrayName][index];
            } else {
              parentObject = parentObject[part];
            }
          }

          // Find arrays that depend on this boolean in the UI schema
          const parentPath = parts.slice(0, -2).join("."); // Path to the parent object
          Object.entries(schema.properties.Parts.items.properties).forEach(
            ([key, fieldSchema]) => {
              // Check if this field has a UI schema condition
              const fieldUiSchema = uiSchema?.Parts?.items?.[key];
              const displayIf = fieldUiSchema?.["ui:displayIf"];

              if (displayIf && displayIf === `partInfo.${lastPart}`) {
                if (!value) {
                  // When boolean is false, clear the array
                  parentObject[key] = [];
                } else {
                  // When boolean is true, initialize with default data
                  parentObject[key] = [createDefaultData(fieldSchema.items)];
                }
              }
            }
          );
        }
      }

      return newData;
    });
  };

  const addArrayItem = (path, parentPath = "") => {
    setFormData((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));
      let current = newData;
      const parts = path.split(".");

      // Navigate to the parent object first
      if (parentPath) {
        const parentParts = parentPath.split(".");
        for (const part of parentParts) {
          if (part.includes("[")) {
            const [arrayName, indexStr] = part.split("[");
            const index = parseInt(indexStr);
            current = current[arrayName][index];
          } else {
            current = current[part];
          }
        }
      }

      // Then navigate to and modify the target array
      for (let i = 0; i < parts.length - 1; i++) {
        current = current[parts[i]];
      }

      const lastPart = parts[parts.length - 1];
      if (!current[lastPart]) {
        current[lastPart] = [];
      }

      // Find the schema for this array
      let arraySchema = schema;
      const fullPath = parentPath ? `${parentPath}.${path}` : path;
      const schemaPath = fullPath.split(".");

      for (const part of schemaPath) {
        if (part.includes("[")) {
          const [arrayName] = part.split("[");
          arraySchema = arraySchema.properties[arrayName].items;
        } else {
          arraySchema = arraySchema.properties[part];
        }
      }

      current[lastPart].push(createDefaultData(arraySchema.items));
      return newData;
    });
  };

  const duplicateArrayItem = (path, index, parentPath = "") => {
    setFormData((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));
      let current = newData;

      // Navigate to the parent object first
      if (parentPath) {
        const parentParts = parentPath.split(".");
        for (const part of parentParts) {
          if (part.includes("[")) {
            const [arrayName, indexStr] = part.split("[");
            const index = parseInt(indexStr);
            current = current[arrayName][index];
          } else {
            current = current[part];
          }
        }
      }

      // Then navigate to the target array
      const parts = path.split(".");
      for (const part of parts) {
        current = current[part];
      }

      if (Array.isArray(current) && current[index]) {
        const duplicated = JSON.parse(JSON.stringify(current[index]));
        current.splice(index + 1, 0, duplicated);
      }

      return newData;
    });
  };

  const removeArrayItem = (path, index, parentPath = "") => {
    setFormData((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));
      let current = newData;

      // Navigate to the parent object first
      if (parentPath) {
        const parentParts = parentPath.split(".");
        for (const part of parentParts) {
          if (part.includes("[")) {
            const [arrayName, indexStr] = part.split("[");
            const index = parseInt(indexStr);
            current = current[arrayName][index];
          } else {
            current = current[part];
          }
        }
      }

      // Then navigate to the target array
      const parts = path.split(".");
      for (const part of parts) {
        current = current[part];
      }

      if (Array.isArray(current) && current.length > 1) {
        current.splice(index, 1);
      }

      return newData;
    });
  };

  const getOrderedProperties = (properties, path) => {
    // Get UI Schema for current path
    let currentUiSchema = getUiSchemaAtPath(uiSchema, path);

    // Get order from UI Schema
    const order = currentUiSchema["ui:order"];

    if (!order) {
      return Object.entries(properties);
    }

    // Create ordered array of properties
    const orderedProperties = [];

    // First add all properties in the specified order
    order.forEach((propertyName) => {
      if (properties[propertyName]) {
        orderedProperties.push([propertyName, properties[propertyName]]);
      }
    });

    // Then add any remaining properties that weren't in the order
    Object.entries(properties).forEach(([key, value]) => {
      if (!order.includes(key)) {
        orderedProperties.push([key, value]);
      }
    });

    return orderedProperties;
  };

  const getUiSchemaAtPath = (uiSchema, path) => {
    if (!path) return uiSchema;

    const parts = path.split(".");
    let currentUiSchema = uiSchema;

    for (const part of parts) {
      if (part.includes("[")) {
        // Handle array indices
        const [arrayName] = part.split("[");
        // For array items, look in the items object of the parent
        currentUiSchema = currentUiSchema?.[arrayName]?.items || {};
      } else {
        currentUiSchema = currentUiSchema?.[part] || {};
      }
    }

    return currentUiSchema;
  };

  const shouldDisplayField = (fieldName, parentPath, parentData) => {
    const currentUiSchema = getUiSchemaAtPath(uiSchema, parentPath);
    const displayIf = currentUiSchema?.[fieldName]?.["ui:displayIf"];

    if (!displayIf) return true;

    // Handle the case where we're checking an array item's parent
    //const parentParts = parentPath.split(".");
    let checkData = parentData;

    // If we have an array index, we need to get the correct parent object
    if (parentPath.includes("[")) {
      // Get the base path without the array index
      const basePath = parentPath.split("[")[0];
      const arrayIndexMatch = parentPath.match(/\[(\d+)\]/);
      if (arrayIndexMatch) {
        const index = parseInt(arrayIndexMatch[1]);
        // Navigate to the correct array item
        const parts = basePath.split(".");
        let current = formData;
        for (const part of parts) {
          if (!current[part]) break;
          current = current[part];
        }
        checkData = current[index];
      }
    }

    // Split the display condition path into parts
    const conditionParts = displayIf.split(".");

    // Navigate through the nested structure following the condition path
    let conditionValue = checkData;
    for (const part of conditionParts) {
      if (!conditionValue || typeof conditionValue !== "object") {
        return false;
      }
      conditionValue = conditionValue[part];
    }

    return conditionValue === true;
  };

  const getWidgetType = (fieldSchema, path) => {
    const currentUiSchema = getUiSchemaAtPath(uiSchema, path);

    // First check if there's a widget specified in the UI schema
    const widget = currentUiSchema?.["ui:widget"];
    if (widget) return widget;

    // If no widget is specified, use defaults based on type
    if (fieldSchema.type === "boolean") return "checkbox";
    if (fieldSchema.enum) return "select";

    return "text";
  };

  const renderSection = (
    sectionSchema,
    data,
    path = "",
    index = undefined,
    isArrayItem = false,
    depth = 0,
    parentPath = "",
    parentData = ""
  ) => {
    if (!sectionSchema || (!sectionSchema.properties && !sectionSchema.items))
      return null;

    const properties =
      sectionSchema.properties ||
      (sectionSchema.items && sectionSchema.items.properties);
    if (!properties) return null;

    // Check if this section should be displayed based on conditions
    if (path && !shouldDisplayField(path.split(".").pop(), parentPath, data)) {
      return null;
    }

    // Compute the full path to the current section
    const fullPath = parentPath
      ? index !== undefined
        ? `${parentPath}.${path}[${index}]`
        : `${parentPath}.${path}`
      : index !== undefined
      ? `${path}[${index}]`
      : path;

    const sectionKey = `${parentPath}${path}-${index ?? ""}`;
    const isExpanded = expandedSections[sectionKey];
    const hasError = hasErrorInSection(path, index);
    const orderedProperties = getOrderedProperties(
      properties,
      isArrayItem ? `${path}.items` : path
    );
    const newParentData = data;

    return (
      <div
        key={sectionKey}
        className={`border rounded-lg bg-white shadow-sm ${
          depth === 0 ? "mb-4" : "mb-2"
        } ${hasError ? "border-red-500 border-2" : ""}`}
      >
        <div
          className={`sticky top-0 z-10 flex items-center justify-between p-4
          ${hasError ? "bg-red-50" : "bg-white"}`}
        >
          <button
            onClick={() => toggleExpand(sectionKey)}
            className={`flex items-center text-lg font-semibold ${
              hasError ? "text-red-600" : "text-gray-700"
            } flex-grow`}
          >
            {isExpanded ? (
              <ChevronDown className="w-5 h-5 mr-2" />
            ) : (
              <ChevronRight className="w-5 h-5 mr-2" />
            )}
            {sectionSchema.title || path}
            {index !== undefined && ` #${index + 1}`}
            {hasError && (
              <span className="ml-2 text-sm text-red-500">
                (Contains errors)
              </span>
            )}
          </button>

          {isArrayItem && (
            <div className="flex gap-2 ml-4">
              <button
                onClick={() => addArrayItem(path, parentPath)}
                className="flex items-center gap-1 px-3 py-1 text-white bg-green-600 rounded-md hover:bg-green-700"
              >
                <PlusCircle className="w-4 h-4" />
                {uiSchema?.[path]?.["ui:addLabel"] || "Add"}
              </button>
              <button
                onClick={() => duplicateArrayItem(path, index, parentPath)}
                className="flex items-center gap-1 px-3 py-1 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                <Copy className="w-4 h-4" />
                Copy
              </button>
              {index > 0 && (
                <button
                  onClick={() => removeArrayItem(path, index, parentPath)}
                  className="flex items-center gap-1 px-3 py-1 text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  <MinusCircle className="w-4 h-4" />
                  Remove
                </button>
              )}
            </div>
          )}
        </div>

        {isExpanded && (
          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 260px)" }}
          >
            <div className={"p-4"}>
              <div className="grid grid-cols-2 gap-4">
                {orderedProperties.map(([key, fieldSchema]) => {
                  const newParentPath = fullPath;
                  const newPath = key;
                  if (key === "type" && path === "") return null;

                  if (fieldSchema.type === "array") {
                    return (
                      <div key={key} className="col-span-2">
                        <div className="mb-2 font-medium">
                          {fieldSchema.title}
                        </div>
                        <div className="space-y-2">
                          {(data[key] || []).map((item, idx) =>
                            renderSection(
                              fieldSchema,
                              item,
                              key,
                              idx,
                              true,
                              depth + 1,
                              newParentPath,
                              newParentData
                            )
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (fieldSchema.type === "object") {
                    return (
                      <div key={key} className="col-span-2">
                        {renderSection(
                          fieldSchema,
                          data[key],
                          key,
                          undefined,
                          false,
                          depth + 1,
                          newParentPath,
                          newParentData
                        )}
                      </div>
                    );
                  }
                  const fieldPath = `${fullPath}.${key}`;

                  return (
                    <div key={key} className="flex flex-col">
                      <label
                        className={`text-sm font-medium mb-1 ${
                          errors[fieldPath] ? "text-red-600" : "text-gray-700"
                        }`}
                      >
                        {fieldSchema.title}
                        {fieldSchema.required && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                      </label>
                      {renderField(
                        fieldSchema,
                        data?.[key],
                        (value) => handleInputChange(fieldPath, value),
                        fieldPath
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const validateForm = (data, schemaToValidate, path = "") => {
    let newErrors = {};
    let errorSectionsSet = new Set();

    const validateField = (fieldSchema, fieldData, fieldPath) => {
      // Only validate required fields when empty
      if (
        fieldSchema.required &&
        (fieldData === undefined || fieldData === null || fieldData === "")
      ) {
        newErrors[fieldPath] = "This field is required";
        // Add all parent sections to errorSections
        const pathParts = fieldPath.split(".");
        let currentPath = "";
        pathParts.forEach((part) => {
          if (currentPath) currentPath += ".";
          currentPath += part;
          errorSectionsSet.add(currentPath);
          // Also add array paths if present
          if (part.includes("[")) {
            errorSectionsSet.add(currentPath.split("[")[0]);
          }
        });
      }

      // Only validate type if the field has a value
      if (fieldData !== undefined && fieldData !== "" && fieldData !== null) {
        switch (fieldSchema.type?.toLowerCase()) {
          case "float":
          case "number":
            if (isNaN(parseFloat(fieldData))) {
              newErrors[fieldPath] = "Must be a number";
              const pathParts = fieldPath.split(".");
              let currentPath = "";
              pathParts.forEach((part) => {
                if (currentPath) currentPath += ".";
                currentPath += part;
                errorSectionsSet.add(currentPath);
              });
            }
            break;
          case "string":
            if (typeof fieldData !== "string") {
              newErrors[fieldPath] = "Must be text";
              const pathParts = fieldPath.split(".");
              let currentPath = "";
              pathParts.forEach((part) => {
                if (currentPath) currentPath += ".";
                currentPath += part;
                errorSectionsSet.add(currentPath);
              });
            }
            break;
        }
      }
    };

    const validateObject = (schema, data, currentPath) => {
      if (!schema.properties && !schema.items) return;

      const properties =
        schema.properties || (schema.items && schema.items.properties);
      if (!properties) return;

      Object.entries(properties).forEach(([key, fieldSchema]) => {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (fieldSchema.type === "array") {
          if (data?.[key]) {
            data[key].forEach((item, index) => {
              validateObject(fieldSchema.items, item, `${newPath}[${index}]`);
            });
          }
        } else if (fieldSchema.type === "object") {
          validateObject(fieldSchema, data?.[key] || {}, newPath);
        } else {
          validateField(fieldSchema, data?.[key], newPath);
        }
      });
    };

    validateObject(schemaToValidate, data, path);
    setErrorSections(errorSectionsSet);
    return newErrors;
  };

  const hasErrorInSection = (path, index = undefined) => {
    // Check if the exact path has an error
    const exactPath = index !== undefined ? `${path}[${index}]` : path;
    if (errorSections.has(exactPath)) return true;

    // Check if any child paths have errors
    for (const errorPath of errorSections) {
      if (
        errorPath.startsWith(`${exactPath}.`) ||
        errorPath.startsWith(`${exactPath}[`)
      ) {
        return true;
      }
    }

    return false;
  };

  const debugPath = (path, value, source = "") => {
    console.log(`[${source}] Path: ${path}, Value:`, value);
  };

  const getFieldPath = (path, index, key) => {
    const parts = [];

    // If there's a path, process it
    if (path) {
      const pathParts = path.split(".");

      pathParts.forEach((part) => {
        if (part.includes("[")) {
          // Already has array index
          parts.push(part);
        } else {
          parts.push(part);
        }
      });
    }

    // Add the current level's array index if it exists
    if (index !== undefined) {
      const lastPart = parts.pop();
      if (lastPart) {
        parts.push(`${lastPart}[${index}]`);
      }
    }

    // Add the field key
    if (key) {
      parts.push(key);
    }

    return parts.join(".");
  };

  const handleSubmit = async (isDraft = false) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      if (isDraft) {
        // Draft logic remains the same
        await onDraft?.(formData);
        setErrors({});
        setErrorSections(new Set());
      } else {
        // First do the basic form validation
        const validationErrors = validateForm(formData, schema);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
          // Handle required fields validation as before
          const requiredFieldErrors = Object.entries(validationErrors).filter(
            ([path]) => {
              const pathParts = path.split(".");
              let current = schema;
              for (const part of pathParts) {
                if (part.includes("[")) {
                  const [arrayName, indexStr] = part.split("[");
                  current = current.properties[arrayName].items;
                } else {
                  current = current.properties?.[part];
                }
              }
              return current?.required;
            }
          );

          if (requiredFieldErrors.length > 0) {
            // Expand sections with errors
            const newExpandedSections = { ...expandedSections };
            Object.keys(validationErrors).forEach((errorPath) => {
              const section = errorPath.split(".")[0];
              newExpandedSections[section] = true;
            });
            setExpandedSections(newExpandedSections);

            message.error(
              `Please fill in all required fields (${requiredFieldErrors.length} missing)`
            );
            setIsSubmitting(false);
            return;
          }
        }

        // If basic validation passes, send to external validator
        try {
          const validationResponse = await validateDataWithExternalService(
            formData
          );
          if (!validationResponse.isValid) {
            // Handle validation errors
            const newErrors = {};
            const newErrorSections = new Set();

            // Process validation response and map errors to fields
            validationResponse.validationResults.forEach((error) => {
              // Assuming error format: { path: "Produit.field", message: "Error message", type: "error|warning" }
              newErrors[error.path] = error.message;

              // Add parent sections to error sections
              const pathParts = error.path.split(".");
              let currentPath = "";
              pathParts.forEach((part) => {
                if (currentPath) currentPath += ".";
                currentPath += part;
                newErrorSections.add(currentPath);

                // Handle array paths
                if (part.includes("[")) {
                  newErrorSections.add(currentPath.split("[")[0]);
                }
              });

              // Show error/warning message based on type
              /*if (error.severity === "error") {
                message.error(`${error.message} (${error.path})`);
              } else if (error.severity === "warning") {
                message.warning(`${error.message} (${error.path})`);
              }*/
            });

            // Update error state
            setErrors(newErrors);
            setErrorSections(newErrorSections);

            // Expand sections with errors
            const newExpandedSections = { ...expandedSections };
            validationResponse.errors.forEach((error) => {
              const section = error.path.split(".")[0];
              newExpandedSections[section] = true;
            });
            setExpandedSections(newExpandedSections);

            // If there are blocking errors, stop submission
            if (
              validationResponse.errors.some((error) => error.type === "error")
            ) {
              setIsSubmitting(false);
              return;
            }
          }

          // If validation passes or only has warnings, proceed with submission
          await onSubmit?.(formData);
          message.success("Form submitted successfully");
        } catch (validationError) {
          console.error("Validation service error:", validationError);
          message.error("Error during data validation. Please try again.");
          setIsSubmitting(false);
          return;
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Error saving form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to call validation service
  const validateDataWithExternalService = async (data) => {
    try {
      return await validateDataPEF(data);
    } catch (error) {
      console.error("Error validating data:", error);
      throw new Error("Failed to validate data");
    }
  };

  if (!schema || !formData) return null;

  return (
    <div className="max-w-6xl mx-auto p-6 bg-gray-50">
      <div className="space-y-4">
        {" "}
        {renderSection(schema, formData, "", undefined, false, 0, "", formData)}
      </div>
      {mode === "preview" ? null : (
        <div className="sticky bottom-0 bg-gray-50 pt-4">
          <div className="flex justify-end gap-4">
            <button
              onClick={() => handleSubmit(true)}
              disabled={isSubmitting}
              className="flex items-center justify-center gap-2 bg-gray-600 text-white py-2 px-6 rounded-md hover:bg-gray-700 transition-colors disabled:opacity-50"
            >
              <DraftingCompass className="w-5 h-5" />
              Save as Draft
            </button>
            <button
              onClick={() => handleSubmit(false)}
              disabled={isSubmitting}
              className="flex items-center justify-center gap-2 bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
            >
              <Save className="w-5 h-5" />
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default SchemaBasedDataGrid;
