import React, { useState, useEffect, useMemo, useContext } from "react";
import { getSupplierProducts } from "../../services/supplierService";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBoxOpen, FaChartPie, FaChartBar } from "react-icons/fa";

import { ClipLoader } from "react-spinners";
import { AuthContext } from "../../contexts/AuthContext";

const SupplierProductList = () => {
  const { user } = useContext(AuthContext);

  // State variables
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  // Fetch products, brands, and suppliers on component mount
  useEffect(() => {
    fetchSupplierProducts(user.id);
  }, []);

  // Fetch all products
  const fetchSupplierProducts = async (supplierId) => {
    setLoading(true);
    try {
      const response = await getSupplierProducts(supplierId);
      const fetchedProducts = response.data.data;

      setProducts(fetchedProducts);
    } catch (err) {
      console.error("Error fetching supplier products:", err);
      toast.error("Failed to fetch supplier products.");
    } finally {
      setLoading(false);
    }
  };

  // Handle view details action
  const handleViewDetails = (productId) => {
    // Get path from current URL and navigate to product detail page
    const currentPath = window.location.pathname;
    navigate(`${currentPath}/${productId}`);
  };

  // Define DataGrid columns
  const productColumns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
    },
    {
      title: "Published At",
      dataIndex: "published_at",
      key: "published_at",
      sorter: (a, b) => new Date(a.published_at) - new Date(b.published_at),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      sortable: false,
    },
  ];

  // Map products to DataGrid data, including action buttons
  const mappedProducts = useMemo(() => {
    return products.map((product) => ({
      id: product.id,
      name: product.attributes.name,
      brand_name: product.attributes.brand_name,
      type: product.attributes.type || "N/A",
      published_at: product.attributes.created_at
        ? new Date(product.attributes.published_at).toLocaleString()
        : "N/A",
      actions: (
        <div className="flex space-x-2">
          <p>{product.attributes.relationships}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(product.id);
            }}
            className="px-2 py-1 bg-blue-500 text-white rounded"
            title="View Product Details"
            aria-label={`View details of ${product.attributes.name}`}
          >
            View
          </button>
        </div>
      ),
    }));
  }, [products, handleViewDetails]);

  // Calculate KPIs
  const totalProducts = products.length;
  const publishedProducts = products.filter(
    (product) => product.attributes.status === "active"
  ).length;
  const draftProducts = products.filter(
    (product) => product.attributes.status === "draft"
  ).length;
  const archivedProducts = products.filter(
    (product) => product.attributes.status === "archived"
  ).length;

  // Define KPI configurations
  const productKPIs = [
    {
      title: "Total Products",
      value: totalProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Active Products",
      value: publishedProducts,
      icon: <FaChartBar className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "Draft Products",
      value: draftProducts,
      icon: <FaChartPie className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-yellow-500",
    },
    {
      title: "Archived Products",
      value: archivedProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-red-500",
    },
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">All Products</h2>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={productKPIs} />

      {/* DataGrid and Loading State */}
      {loading ? (
        <div className="flex justify-center items-center text-gray-500">
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <DataGrid
            columns={productColumns}
            data={mappedProducts}
            onRowClick={null} // No row click handling as actions are within the row
          />
        </>
      )}
      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
};

export default SupplierProductList;
