import React, { useState, useEffect } from "react";
import {
  getFormTemplates,
  createFormTemplate,
  updateFormTemplate,
  deleteFormTemplate,
} from "../../services/formTemplateService";
import EntityList from "../../components/ui/cards/EntityList";
import EntityForm from "../../components/ui/form/EntityForm";
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal, message, Checkbox } from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { FaPlus } from "react-icons/fa";

const ManageForms = () => {
  const [formTemplates, setFormTemplates] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [editingForm, setEditingForm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchFormTemplates();
  }, []);

  const fetchFormTemplates = () => {
    getFormTemplates()
      .then((response) => {
        setFormTemplates(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching form templates", error);
        message.error("Failed to fetch form templates.");
      });
  };

  const formFields = [
    {
      label: "Name",
      name: "name",
      required: true,
      rules: [{ required: true, message: "Please input the Name" }],
    },
    {
      label: "Code",
      name: "code",
      required: true,
      rules: [{ required: true, message: "Please input the Code" }],
    },
    {
      label: "Version",
      name: "version",
      type: "number",
      required: true,
      rules: [{ required: true, message: "Please input the Version" }],
    },
    {
      label: "Schema (JSON)",
      name: "schema",
      type: "textarea",
      required: true,
      rows: 6,
      rules: [{ required: true, message: "Please input the Schema" }],
    },
    {
      label: "UI Schema (JSON)",
      name: "ui_schema",
      type: "textarea",
      required: true,
      rows: 6,
      rules: [{ required: true, message: "Please input the UI Schema" }],
    },
    {
      label: "Active",
      name: "active",
      type: "checkbox",
    },
  ];

  const handleFinish = (values) => {
    try {
      const formTemplateData = {
        ...values,
        schema: JSON.parse(values.schema),
        ui_schema: JSON.parse(values.ui_schema),
        active: values.active || false,
      };

      if (editingForm) {
        updateFormTemplate(editingForm.id, formTemplateData)
          .then(() => {
            message.success("Form template updated successfully");
            resetForm();
            fetchFormTemplates();
          })
          .catch((error) => {
            console.error("Error updating form template", error);
          });
      } else {
        createFormTemplate(formTemplateData)
          .then(() => {
            message.success("Form template created successfully");
            resetForm();
            fetchFormTemplates();
          })
          .catch((error) => {
            console.error("Error creating form template", error);
          });
      }
    } catch (err) {
      message.error("Invalid JSON in Schema or UI Schema");
      console.error("JSON Parse Error", err);
    }
  };

  const resetForm = () => {
    form.resetFields();
    setEditingForm(null);
    setIsModalOpen(false);
  };

  const openModalForEditing = (formTemplate) => {
    setEditingForm(formTemplate);
    form.setFieldsValue({
      ...formTemplate.attributes,
      schema: JSON.stringify(formTemplate.attributes.schema, null, 2),
      ui_schema: JSON.stringify(formTemplate.attributes.ui_schema, null, 2),
      id: formTemplate.id, // Included ID to differentiate between create and edit
    });
    setIsModalOpen(true);
  };

  const handleEdit = (formTemplate) => {
    openModalForEditing(formTemplate);
  };

  const handleSelect = (formId) => {
    setSelectedForms((prevSelected) =>
      prevSelected.includes(formId)
        ? prevSelected.filter((id) => id !== formId)
        : [...prevSelected, formId]
    );
  };

  const handleSelectAll = () => {
    if (selectedForms.length === formTemplates.length) {
      setSelectedForms([]);
    } else {
      setSelectedForms(formTemplates.map((form) => form.id));
    }
  };

  const handleDelete = (formTemplateId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this form template?",
      onOk: () => {
        deleteFormTemplate(formTemplateId)
          .then(() => {
            message.success("Form template deleted successfully");
            fetchFormTemplates();
          })
          .catch((error) => {
            console.error("Error deleting form template", error);
          });
      },
    });
  };

  const handleViewDetails = (formID) => {
    navigate(`/admin/forms/${formID}`);
  };

  const entityFields = [
    { name: "code", label: "Code" },
    { name: "version", label: "Version" },
  ];

  const actions = [
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: "view",
      label: "View",
      icon: <EyeOutlined />,
      onClick: (entity) => handleViewDetails(entity.id),
    },
    {
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: (entity) => handleDelete(entity.id),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Forms</h2>
        <Button
          onClick={() => {
            setEditingForm(null);
            form.resetFields();
            setIsModalOpen(true);
          }}
          type="primary"
        >
          <FaPlus className="mr-2" /> Add New Form
        </Button>
      </div>
      <div className="mb-4">
        {selectedForms.length > 0 && (
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete selected forms?",
                onOk: () => {
                  selectedForms.forEach((id) => handleDelete(id));
                  setSelectedForms([]);
                },
              });
            }}
          >
            Delete Selected
          </Button>
        )}
      </div>

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFinish}
        editingEntity={editingForm}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={formFields}
        entityName="Form Template"
        form={form}
      />

      {/* Select All Checkbox */}
      <Checkbox
        checked={selectedForms.length === formTemplates.length}
        onChange={handleSelectAll}
        style={{ marginBottom: 16 }}
      >
        Select All
      </Checkbox>

      {/* Entity List */}
      <EntityList
        entities={formTemplates}
        fields={entityFields}
        actions={actions}
        onSelect={handleSelect}
        selectedEntities={selectedForms}
        statusField="active"
        StatusBadgeComponent={StatusBadge}
        searchBy={["name", "code"]}
        filters={[
          {
            name: "active",
            label: "Status",
            options: [
              ...Array.from(new Set(formTemplates.map((form) => form.attributes.active))).map(
                (status) => ({
                  value: status,
                  label: status ? "Active" : "Inactive",
                })
              ),
            ],
          },
        ]}
      />
    </div>
  );
};

export default ManageForms;
