// src/pages/brands/BrandDetail.js
import React, { useEffect, useState } from "react";
import { getBrand } from "../../services/brandService";
import { useParams, useNavigate } from "react-router-dom";
import InfoCard from "../../components/ui/cards/InfoCard";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { Button } from "antd";

const BrandDetail = () => {
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const navigate = useNavigate();
  const [UsersData, setUsersData] = useState([]);
  const [ProductsData, setProductsData] = useState([]);
  const [SuppliersData, setSuppliersData] = useState([]);

  useEffect(() => {
    fetchBrandDetails();
  }, [id]);

  const fetchBrandDetails = () => {
    getBrand(id)
      .then((response) => {
        setBrand(response.data);

        response.data.included.forEach((element) => {
          if (element.type === "user") {
            setUsersData((prev) => [...prev, element]);
          }
          if (element.type === "product") {
            setProductsData((prev) => [...prev, element]);
          }
          if (element.type === "supplier") {
            setSuppliersData((prev) => [...prev, element]);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching brand details", error);
      });
  };

  if (!brand) {
    return <div className="p-6">Loading...</div>;
  }

  // Define fields for InfoCard
  const brandFields = [
    { name: "address", label: "Address" },
    { name: "country", label: "Country" },
    {
      name: "license_types",
      label: "License Types",
      render: (value) => value.join(", "),
    },
    {
      name: "created_at",
      label: "Created At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
    {
      name: "updated_at",
      label: "Updated At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
  ];

  // Prepare suppliers data for DataGrid
  const suppliers = SuppliersData.map((supplier) => ({
    id: supplier.id,
    name: supplier.attributes.name,
    tva_number: supplier.attributes.tva_number,
    country: supplier.attributes.country,
  }));

  // Prepare products data for DataGrid
  const products = ProductsData.map((product) => ({
    id: product.id,
    name: product.attributes.name,
    status: product.attributes.status,
    reference_brand: product.attributes.reference_brand,
    reference_supplier: product.attributes.reference_supplier,
    supplier: SuppliersData.find(
      (supplier) => supplier.id === product.relationships.supplier.data.id
    ).attributes?.name,
  }));

  // Prepare users data for DataGrid
  const users = UsersData.map((user) => ({
    id: user.id,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes.role,
    status: user.attributes.invitation_status,
  }));

  // Define columns for suppliers
  const columnsSupplier = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "TVA Number",
      dataIndex: "tva_number",
      key: "tva_number",
      width: 200,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 200,
    },
  ];

  const handleSupplierClick = (supplier) => {
    navigate(`/admin/suppliers/${supplier.id}`);
  };

  // Define columns for products
  const columnsProduct = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Reference Brand",
      dataIndex: "reference_brand",
      key: "reference_brand",
      width: 200,
    },
    {
      title: "Reference Supplier",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
      width: 200,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
    },
  ];

  const handleProductClick = (product) => {
    navigate(`/admin/manage-products/${product.id}`);
  };

  // Define columns for users
  const columnsUser = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
  ];

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      {/* Brand Information */}
      <InfoCard
        title={brand.data.attributes.name}
        fields={brandFields}
        data={brand.data.attributes}
      />

      {/* Suppliers Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Suppliers</h3>
        <DataGrid
          columns={columnsSupplier}
          data={suppliers}
          onRowClick={handleSupplierClick}
        />
      </div>

      {/* Users Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Users</h3>
        <DataGrid columns={columnsUser} data={users} />
      </div>

      {/* Products Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Products</h3>
        <DataGrid
          columns={columnsProduct}
          data={products}
          onRowClick={handleProductClick}
        />
      </div>
    </div>
  );
};

export default BrandDetail;
