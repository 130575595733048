import React, { useEffect, useState } from "react";
import {
  getProduct,
  updateProductData,
  createProductData,
  deleteProductData,
  getProductData,
} from "../../services/productService";
import { getFormTemplates } from "../../services/formTemplateService";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SchemaBasedDataGrid from "../../components/ui/datagrid/SchemaBasedDataGrid";
import { FaEdit, FaPlus, FaTrash, FaSpinner } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import ProductDetails from "../../components/ProductDetails";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";

const ProductDetailSupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [productDataDetails, setProductDataDetails] = useState([]);
  const [availableFormTemplates, setAvailableFormTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingProductData, setEditingProductData] = useState(null);

  const [formModal, setFormModal] = useState({
    isOpen: false,
    form_data: null,
    mode: "add", // 'add' or 'edit'
    formDataDetail: null, // The product_data_detail being edited
    selectedFormTemplate: null, // Selected form_template for adding
  });

  useEffect(() => {
    fetchProductDetails();
    fetchProductDataDetails();
    fetchFormTemplates();
  }, [id]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const response = await getProduct(id);
      const fetchedProduct = response.data.data;
      setProduct(fetchedProduct);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error("Failed to fetch product details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch available form templates
  const fetchFormTemplates = async () => {
    try {
      const response = await getFormTemplates();
      setAvailableFormTemplates(response.data.data);
    } catch (error) {
      console.error("Error fetching form templates:", error);
      toast.error("Failed to fetch form templates.");
    }
  };

  // Fetch product data details
  const fetchProductDataDetails = async () => {
    try {
      setLoading(true);
      const response = await getProductData(id);
      setProductDataDetails(response.data.data);
      console.log("Product Data Details:", response.data.data);
    } catch (error) {
      console.error("Error fetching product data details:", error);
      toast.error("Failed to fetch product data details.");
    } finally {
      setLoading(false);
    }
  };

  const openFormModal = (mode, formDataDetail = null) => {
    setEditingProductData(formDataDetail);
    if (mode === "add") {
      setFormModal({
        isOpen: true,
        mode,
        id: formDataDetail?.id,
        form_data: null,
        formDataDetail: null,
        selectedFormTemplate: null,
      });
    } else if (mode === "edit") {
      setFormModal({
        isOpen: true,
        mode,
        form_data: formDataDetail.form_data,
        id: formDataDetail.id,
        formDataDetail: formDataDetail.form_template,
        selectedFormTemplate: formDataDetail.attributes?.form_template || null,
      });
    }
  };

  const closeFormModal = () => {
    setFormModal({
      isOpen: false,
      mode: "add",
      id: null,
      formDataDetail: null,
      selectedFormTemplate: null,
    });
  };

  const handleFormSubmit = async (data) => {
    if (formModal.mode === "edit") {
      // Update existing product_data_detail
      try {
        const actionType = "submit";
        await updateProductData(id, formModal.id, data, actionType);
        toast.success("Product data updated successfully.");
        fetchProductDetails();
        fetchProductDataDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error updating product data:", error);
        toast.error("Failed to update product data.");
      }
    } else if (formModal.mode === "add") {
      // Create new product_data_detail
      try {
        await createProductData(id, data);
        toast.success("Product data added successfully.");
        fetchProductDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error adding product data:", error);
        toast.error("Failed to add product data.");
      }
    }
    // Update product data details
  };

  const handleDraft = async (data) => {
    try {
      await updateProductData(id, formModal.id, data);
      toast.success("Product data saved as draft.");
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error saving product data as draft:", error);
      toast.error("Failed to save product data as draft.");
    }
  };

  const handleDelete = async (formId) => {
    if (
      window.confirm("Are you sure you want to delete this product data entry?")
    ) {
      try {
        await deleteProductData(formId);
        toast.success("Product data deleted successfully.");
        fetchProductDetails();
      } catch (error) {
        console.error("Error deleting product data:", error);
        toast.error("Failed to delete product data.");
      }
    }
  };

  const handleFormTemplateSelect = (event) => {
    const selectedFormId = event.target.value;
    console.log("Selected Form Template ID:", selectedFormId);
    const selectedForm = availableFormTemplates.find(
      (form) => form.id === selectedFormId
    );
    console.log("Selected Form Template:", selectedForm);
    setFormModal((prevState) => ({
      ...prevState,
      selectedFormTemplate: selectedForm || null,
    }));
  };

  // Debugging Logs
  useEffect(() => {
    console.log("Form Modal State:", formModal);
  }, [formModal]);

  if (loading) {
    return (
      <div className="p-6 flex flex-col items-center justify-center">
        <FaSpinner className="animate-spin h-8 w-8 text-blue-500" />
        <p className="mt-2 text-gray-600">Loading product details...</p>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="p-6 text-center text-red-500">
        <p>Unable to load the product. Please try again later.</p>
      </div>
    );
  }

  // Prepare product data for display
  const productData = {
    "Product Name": product.attributes.name,
    Brand: product.attributes.brand_name,
    Supplier: product.attributes.supplier_name,
    Type: product.attributes.type || "N/A",
    Status:
      product.attributes.status.charAt(0).toUpperCase() +
      product.attributes.status.slice(1),
    "Reference Brand": product.attributes.reference_brand,
    "Reference Supplier": product.attributes.reference_supplier,
    "Created At": new Date(product.attributes.created_at).toLocaleString(),
    "Updated At": new Date(product.attributes.updated_at).toLocaleString(),
  };

  // Transform productDataDetails for DataGrid
  const transformedData = productDataDetails.map((detail) => ({
    id: detail.id,
    data_type: detail.attributes.data_type,
    "form_template.name": detail.attributes.form_template.name,
    status:
      detail.attributes.status.charAt(0).toUpperCase() +
      detail.attributes.status.slice(1),
    updated_at: new Date(detail.attributes.updated_at).toLocaleDateString(),
    form_data: detail.attributes.form_data,
    form_template: detail.attributes.form_template,
    form_data: detail.attributes.form_data,
  }));

  const columns = [
    {
      title: "Data Type",
      dataIndex: "data_type",
      key: "data_type",
      sorter: (a, b) => a.data_type.localeCompare(b.data_type),
      render: (text) => text || "N/A",
    },
    {
      title: "Form",
      dataIndex: "form_template.name",
      key: "form_template.name",
      sorter: (a, b) =>
        a.form_template.name.localeCompare(b.form_template.name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <button
          onClick={() => openFormModal("edit", record)}
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          aria-label={`Fill data for ${record.name}`}
        >
          Fill Data
        </button>
      ),
    },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer />
      <button
        onClick={() => navigate(-1)}
        className="mb-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
      >
        Back
      </button>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Product Details */}
        <div className="bg-white p-6 rounded shadow">
          <ProductDetails productData={productData} />
        </div>
        {/* Product Data Details Section */}

        {/* Product Specifications */}
        <div className="lg:col-span-2 bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Product Specifications</h3>
          </div>
          <DataGrid
            columns={columns}
            data={transformedData}
            onRowClick={(row) => openFormModal("edit", row)}
          />
        </div>
      </div>

      {/* Form Modal */}
      {formModal.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl mx-4 my-8">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">
                {formModal.mode === "edit"
                  ? "Edit " + product.attributes.name
                  : "Add New Product Data"}
              </h3>
              <button
                onClick={closeFormModal}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                &times;
              </button>
            </div>

            {/* For Adding New Product Data */}
            {formModal.mode === "add" && (
              <div className="mb-4">
                <label
                  htmlFor="formTemplate"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Select Form Template
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  id="formTemplate"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={handleFormTemplateSelect}
                  value={formModal.selectedFormTemplate?.id || ""}
                >
                  <option value="">-- Select Form Template --</option>
                  {availableFormTemplates.map((formTemplate) => (
                    <option key={formTemplate.id} value={formTemplate.id}>
                      {formTemplate.attributes.name} (v
                      {formTemplate.attributes.version})
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Render FormViewer only if a form_template is selected or editing */}
            {(formModal.mode === "edit" || formModal.selectedFormTemplate) && (
              <SchemaBasedDataGrid
                inputSchema={formModal.formDataDetail?.schema}
                initialData={
                  formModal.mode === "edit" ? formModal.form_data || {} : null
                }
                uiSchema={formModal.selectedFormTemplate?.ui_schema}
                onSubmit={handleFormSubmit}
                onDraft={handleDraft}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetailSupplier;

{
  /*<FormViewer
    mode="edit" // Always 'edit' in this context
    onSubmit={handleFormSubmit}
    // Pass the form_template's schema and ui_schema
    customSchema={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_template?.schema || {}
        : formModal.selectedFormTemplate?.schema || {}
    }
    customUiSchema={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_template?.ui_schema || {}
        : formModal.selectedFormTemplate?.ui_schema || {}
    }
    // Pass initial form data if editing, else empty
    formData={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_data || {}
        : {}
    }
    // Pass the formDataDetail's id to identify which one to update
    formId={formModal.mode === 'edit' ? formModal.formDataDetail?.id : null}
  />*/
}
