import React from 'react';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordInput = ({
  name,
  label,
  register,
  validation = {},
  error,
  show,
  toggle,
  placeholder = 'Enter password',
}) => (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-medium mb-2">
      {label}
    </label>
    <div className="relative">
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
        <FaLock className="h-5 w-5 text-gray-400" />
      </span>
      <input
        type={show ? 'text' : 'password'}
        {...register(name, validation)}
        className={`appearance-none block w-full pl-10 pr-10 py-2 border ${
          error ? 'border-red-500' : 'border-gray-300'
        } rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
        placeholder={placeholder}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
        onClick={toggle}
      >
        {show ? (
          <FaEyeSlash className="h-5 w-5 text-gray-400" />
        ) : (
          <FaEye className="h-5 w-5 text-gray-400" />
        )}
      </button>
    </div>
    {error && (
      <p className="text-red-500 text-xs mt-1">{error.message}</p>
    )}
  </div>
);

export default PasswordInput;
