// src/components/common/KPIDashboard.js
import React from 'react';
import PropTypes from 'prop-types';
import KPI from './KPI';

const KPIDashboard = ({ kpis }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      {kpis.map((kpi, index) => (
        <KPI
          key={index}
          title={kpi.title}
          value={kpi.value}
          icon={kpi.icon}
          color={kpi.color}
        />
      ))}
    </div>
  );
};

KPIDashboard.propTypes = {
  kpis: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      icon: PropTypes.element,
      color: PropTypes.string,
    })
  ).isRequired,
};

export default KPIDashboard;
