// src/components/admin/ManageProducts.js
import React, { useState, useEffect, useMemo } from "react";
import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
} from "../../services/productService";
import { getBrands } from "../../services/brandService";
import { getSuppliers } from "../../services/supplierService";
import { getFormTemplates } from "../../services/formTemplateService";
import { Form, message, Button, Modal, Upload } from "antd";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import EntityForm from "../../components/ui/form/EntityForm";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import ImportProductsModal from "./ImportProductsModal";
import { useNavigate } from "react-router-dom";
import {
  FaBoxOpen,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChartPie,
  FaChartBar,
} from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { ClipLoader } from "react-spinners";
import StatusBadge from "../../components/ui/badges/StatusBadge";

const ManageProducts = () => {
  // State variables
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [formTemplate, setFormTemplate] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [filterLicenses, setFilterLicenses] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  // Form instance
  const [form] = Form.useForm();

  // State for selected brand
  const [selectedBrandId, setSelectedBrandId] = useState("");

  // Fetch data in useEffect
  useEffect(() => {
    fetchProducts();
    fetchBrands();
    fetchSuppliers();
    fetchFormTemplates();
  }, []);

  // Fetch products
  const fetchProducts = () => {
    setLoading(true);
    getProducts()
      .then((response) => {
        setProducts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products", error);
        message.error("Failed to fetch products.");
        setLoading(false);
      });
  };

  // Fetch brands
  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brands", error);
        message.error("Failed to fetch brands.");
      });
  };

  // Fetch suppliers
  const fetchSuppliers = () => {
    getSuppliers()
      .then((response) => {
        setSuppliers(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
        message.error("Failed to fetch suppliers.");
      });
  };

  // Fetch form templates
  const fetchFormTemplates = () => {
    getFormTemplates()
      .then((response) => {
        setFormTemplate(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching form templates", error);
        message.error("Failed to fetch form templates.");
      });
  };

  // Handle import success to refresh products
  const handleImportSuccess = () => {
    fetchProducts();
  };

  // Filter suppliers and licenses based on selected brand
  useEffect(() => {
    if (selectedBrandId) {
      const suppliersForBrand = suppliers.filter((supplier) =>
        supplier.attributes.brands
          .map((brand) => brand.id)
          .includes(selectedBrandId)
      );
      setFilteredSuppliers(suppliersForBrand);

      const selectedBrand = brands.find(
        (brand) => brand.id === selectedBrandId
      );
      if (selectedBrand) {
        const licensesForBrand = formTemplate
          .filter((template) =>
            selectedBrand.attributes.license_types.includes(
              template.attributes.code
            )
          )
          .map((template) => ({
            ...template,
            attributes: {
              ...template.attributes,
              name: `${template.attributes.name} (v${template.attributes.version})`,
            },
          }));
        setFilterLicenses(licensesForBrand);
      } else {
        setFilterLicenses([]);
      }
    } else {
      setFilteredSuppliers([]);
      setFilterLicenses([]);
    }
    form.setFieldsValue({
      supplier_id: undefined,
      selected_licenses: undefined,
    });
  }, [selectedBrandId, suppliers, brands, formTemplate, form]);

  // Define form fields for EntityForm
  const formFields = useMemo(
    () => [
      {
        label: "Brand",
        name: "brand_id",
        type: "select",
        required: true,
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
      },
      {
        label: "Supplier",
        name: "supplier_id",
        type: "select",
        required: true,
        options: filteredSuppliers.map((supplier) => ({
          value: supplier.id,
          label: supplier.attributes.name,
        })),
      },
      { label: "Product Name", name: "name", type: "text", required: true },
      {
        label: "Data type",
        name: "selected_licenses",
        type: "multi-select",
        required: true,
        options: filterLicenses.map((license) => ({
          value: license.attributes.code,
          label: license.attributes.name,
        })),
      },
      {
        label: "Status",
        name: "status",
        type: "select",
        required: true,
        options: [
          { value: "draft", label: "Draft" },
          { value: "active", label: "Active" },
          { value: "archived", label: "Archived" },
        ],
      },
      {
        label: "Reference Brand",
        name: "reference_brand",
        type: "text",
        required: true,
      },
      {
        label: "Reference Supplier",
        name: "reference_supplier",
        type: "text",
        required: true,
      },
    ],
    [brands, filteredSuppliers, filterLicenses]
  );

  // Handle form submission
  const handleFinish = (values) => {
    if (editingProduct) {
      // Update existing product
      updateProduct(editingProduct.id, values)
        .then(() => {
          message.success("Product updated successfully");
          resetForm();
          fetchProducts();
        })
        .catch((error) => {
          console.error("Error updating product", error);
          const errorMessage =
            error.response?.data?.message || "Failed to update product.";
          message.error(errorMessage);
        });
    } else {
      // Create new product
      createProduct(values.brand_id, values)
        .then(() => {
          message.success("Product created successfully");
          resetForm();
          fetchProducts();
        })
        .catch((error) => {
          console.error("Error creating product", error);
          const errorMessage =
            error.response?.data?.message || "Failed to create product.";
          message.error(errorMessage);
        });
    }
  };

  // Reset form and close modal
  const resetForm = () => {
    form.resetFields();
    setEditingProduct(null);
    setIsModalOpen(false);
    setCurrentPage(0); // Reset to first page after form submission
    setSelectedBrandId("");
    setIsImportModalOpen(false);
  };

  // Open modal for editing a product
  const openModalForEditing = (product) => {
    setEditingProduct(product);
    const initialValues = {
      brand_id: product.relationships.brand.data.id,
      supplier_id: product.relationships.supplier.data.id,
      name: product.attributes.name,
      selected_licenses: product.attributes.selected_licenses || [],
      status: product.attributes.status,
      reference_brand: product.attributes.reference_brand,
      reference_supplier: product.attributes.reference_supplier,
    };
    form.setFieldsValue(initialValues);
    setSelectedBrandId(product.relationships.brand.data.id);
    setIsModalOpen(true);
  };

  // Handle edit action
  const handleEdit = (product) => {
    openModalForEditing(product);
  };

  // Handle view details action
  const handleViewDetails = (productId) => {
    const currentPath = window.location.pathname;
    navigate(`${currentPath}/${productId}`);
  };

  // Handle delete action
  const handleDelete = (productId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this product?",
      onOk: () => {
        deleteProduct(productId)
          .then(() => {
            message.success("Product deleted successfully");
            fetchProducts();
          })
          .catch((error) => {
            console.error("Error deleting product", error);
            const errorMessage =
              error.response?.data?.message || "Failed to delete product.";
            message.error(errorMessage);
          });
      },
    });
  };

  // Define DataGrid columns
  const productColumns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
      filters: brands.map((brand) => ({
        text: brand.attributes.name,
        value: brand.attributes.name,
      })),
      onFilter: (value, record) => record.brand_name === value,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
      filters: suppliers.map((supplier) => ({
        text: supplier.attributes.name,
        value: supplier.attributes.name,
      })),
      onFilter: (value, record) => record.supplier_name === value,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusBadge status={status} />,
      // filters get avaliable status prom products with no duplicates
      filters: Array.from(new Set(products.map((product) => product.attributes.status))
      ).map((status) => ({
        text: status.charAt(0).toUpperCase() + status.slice(1),
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Published At",
      dataIndex: "published_at",
      key: "published_at",
      sorter: (a, b) => new Date(a.published_at) - new Date(b.published_at),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      sortable: false,
    },
  ];
  

  // Map products to DataGrid data, including action buttons
  const mappedProducts = useMemo(() => {
    return products.map((product) => ({
      id: product.id,
      name: product.attributes.name,
      brand_name: product.attributes.brand_name,
      supplier_name: product.attributes.supplier_name,
      type: product.attributes.type || "N/A",
      status:
        product.attributes.status.charAt(0).toUpperCase() +
        product.attributes.status.slice(1),
      published_at: product.attributes.created_at
        ? new Date(product.attributes.published_at).toLocaleString()
        : "N/A",
      actions: (
        <div className="flex space-x-2">
          <Button
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering row click
              handleEdit(product);
            }}
            type="primary"
            size="small"
            icon={<FaEdit />}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(product.id);
            }}
            danger
            type="primary"
            size="small"
            icon={<FaTrash />}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(product.id);
            }}
            size="small"
          >
            View
          </Button>
        </div>
      ),
    }));
  }, [products]);

  // Calculate KPIs
  const totalProducts = products.length;
  const publishedProducts = products.filter(
    (product) => product.attributes.status === "active"
  ).length;
  const draftProducts = products.filter(
    (product) => product.attributes.status === "draft"
  ).length;
  const archivedProducts = products.filter(
    (product) => product.attributes.status === "archived"
  ).length;

  // Define KPI configurations
  const productKPIs = [
    {
      title: "Total Products",
      value: totalProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Active Products",
      value: publishedProducts,
      icon: <FaChartBar className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "Draft Products",
      value: draftProducts,
      icon: <FaChartPie className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-yellow-500",
    },
    {
      title: "Archived Products",
      value: archivedProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-red-500",
    },
  ];

  // Pagination logic
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // Handle form values change
  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.brand_id !== undefined) {
      setSelectedBrandId(changedValues.brand_id);
      // Reset supplier_id and selected_licenses when brand_id changes
      form.setFieldsValue({
        supplier_id: undefined,
        selected_licenses: undefined,
      });
    }
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Products</h2>
        <div className="flex space-x-4">
          <Button
            onClick={() => setIsModalOpen(true)}
            type="primary"
            icon={<FaPlus />}
          >
            Add New Product
          </Button>
          <Button
            onClick={() => setIsImportModalOpen(true)}
            type="default"
            icon={<Upload />}
          >
            Import Products (CSV)
          </Button>
        </div>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={productKPIs} />

      {/* DataGrid and Loading State */}
      {loading ? (
        <div className="flex justify-center items-center text-gray-500">
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <DataGrid
            columns={productColumns}
            data={mappedProducts}
            onRowClick={null} // No row click handling as actions are within the row
          />
        </>
      )}

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFinish}
        editingEntity={editingProduct}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={formFields}
        entityName="Product"
        form={form}
        onValuesChange={handleValuesChange}
      />

      {/* Import Products Modal */}
      <ImportProductsModal
        visible={isImportModalOpen}
        onClose={resetForm}
        onImportSuccess={handleImportSuccess}
      />
    </div>
  );
};

export default ManageProducts;
