import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../components/ui//PrivateRoute";
import Layout from "../components/ui/Layout";
import AdminDashboard from "../features/admin/AdminDashboard";
import ManageBrands from "../features/admin/ManageBrands";
import BrandDetail from "../features/admin/BrandDetail";
import ManageSuppliers from "../features/admin/ManageSuppliers";
import ManageForms from "../features/admin/ManageForms";
import ManageUsers from "../features/admin/ManageUsers";
import ManageProducts from "../features/admin/ManageProducts";
import FormViewer from "../components/FormViewer";
import ProductDetail from "../features/admin/ProductDetail";
import Settings from '../components/common/Settings';
import SupplierDetail from '../features/admin/SupplierDetail';

const AdminRoutes = () => (
  <PrivateRoute roles={["admin"]}>
    <Layout>
      <Routes>
        <Route path="" element={<AdminDashboard />} />
        <Route path="manage-brands" element={<ManageBrands />} />
        <Route path="brands/:id" element={<BrandDetail />} />
        <Route path="suppliers/:id" element={<SupplierDetail />} />
        <Route path="manage-suppliers" element={<ManageSuppliers />} />
        <Route path="manage-forms" element={<ManageForms />} />
        <Route path="manage-users" element={<ManageUsers />} />
        <Route path="manage-products" element={<ManageProducts />} />
        <Route path="forms/:id" element={<FormViewer mode="preview" />} />
        <Route path="manage-products/:id" element={<ProductDetail />} />
        <Route path="settings" element={<Settings />} />
      </Routes>
    </Layout>
  </PrivateRoute>
);

export default AdminRoutes;
