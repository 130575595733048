// src/components/layout/Sidebar.js
import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Layout, Menu, Modal } from 'antd';
import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  FormOutlined,
  TeamOutlined,
  UserAddOutlined,
  AppstoreOutlined,
  CustomerServiceOutlined,
} from '@ant-design/icons';
import SupportForm from "../SupportForm";

const { Sider } = Layout;

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  // State to manage SupportForm modal visibility
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  // Define menu items based on user role
  const menuItems = [
    {
      key: `/${user.role.split("_")[0]}`,
      icon: <DashboardOutlined />,
      label: <Link to={`/${user.role.split("_")[0]}`}>Dashboard</Link>,
    },
  ];

  if (user.role === "admin") {
    menuItems.push(
      {
        key: "/admin/manage-brands",
        icon: <ShopOutlined />,
        label: <Link to="/admin/manage-brands">Manage Brands</Link>,
      },
      {
        key: "/admin/manage-suppliers",
        icon: <UserOutlined />,
        label: <Link to="/admin/manage-suppliers">Manage Suppliers</Link>,
      },
      {
        key: "/admin/manage-products",
        icon: <AppstoreOutlined />,
        label: <Link to="/admin/manage-products">Manage Products</Link>,
      },
      {
        key: "/admin/manage-forms",
        icon: <FormOutlined />,
        label: <Link to="/admin/manage-forms">Manage Forms</Link>,
      },
      {
        key: "/admin/manage-users",
        icon: <TeamOutlined />,
        label: <Link to="/admin/manage-users">Manage Users</Link>,
      }
    );
  } else if (user.role === "brand") {
    menuItems.push(
      {
        key: "/brand/add-supplier",
        icon: <UserAddOutlined />,
        label: <Link to="/brand/add-supplier">Suppliers</Link>,
      },
      {
        key: "/brand/add-product",
        icon: <AppstoreOutlined />,
        label: <Link to="/brand/add-product">Products</Link>,
      }
    );
  } else if (user.role === "supplier") {
    menuItems.push(
      {
        key: "/supplier/products",
        icon: <AppstoreOutlined />,
        label: <Link to="/supplier/products">Products</Link>,
      },
      {
        key: "support",
        icon: <CustomerServiceOutlined />,
        label: (
          <span onClick={openSupportModal} style={{ cursor: 'pointer' }}>
            Support
          </span>
        ),
      }
    );
  }

  // Determine the selected menu item based on the current location
  const getSelectedKeys = () => {
    let selectedKey = '';
    let maxLength = 0;
    menuItems.forEach((item) => {
      if (location.pathname.startsWith(item.key) && item.key.length > maxLength) {
        selectedKey = item.key;
        maxLength = item.key.length;
      }
    });
    return [selectedKey];
  };

  const selectedKeys = getSelectedKeys();

  return (
    <>
      <Sider width={220} className="site-layout-background">
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          style={{ height: '100%', borderRight: 0 }}
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      {/* SupportForm Modal */}
      {isSupportModalOpen && <SupportForm onClose={closeSupportModal} />}
    </>
  );
};

export default Sidebar;
