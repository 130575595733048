import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/ui/PrivateRoute";
import Layout from "../components/ui/Layout";
import AddProduct from "../features/brand/AddProduct";
import AddSupplier from "../features/brand/AddSupplier";
import BrandDashboard from "../features/brand/BrandDashboard";

const BrandRoutes = () => (
  <PrivateRoute roles={["brand"]}>
    <Layout>
      <Routes>
        <Route path="" element={<BrandDashboard />} />
        <Route path="add-supplier" element={<AddSupplier />} />
        <Route path="add-product" element={<AddProduct />} />
      </Routes>
    </Layout>
  </PrivateRoute>
);

export default BrandRoutes;
