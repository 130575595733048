// src/components/common/FormViewer.js
import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { getFormTemplate } from "../services/formTemplateService";
import SchemaBasedDataGrid from "./ui/datagrid/SchemaBasedDataGrid";
import { Button } from "antd";

const FormViewer = ({
  mode = "preview",
  onSubmit,
  customSchema,
  customUiSchema,
  title = "",
  formData = {},
  formId = null, // To identify which form to update
}) => {
  const { id } = useParams();
  const [formTemplate, setFormTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();


  console.log("Form Data:", formData);

  useEffect(() => {
    if (!customSchema) {
      fetchFormTemplate();
    } else {
      setLoading(false);
    }
  }, [id]);

  const fetchFormTemplate = async () => {
    try {
      setLoading(true);
      const response = await getFormTemplate(id);
      setFormTemplate(response.data.data);
    } catch (error) {
      console.error("Error fetching form template:", error);
      toast.error("Failed to load form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (loading && id) {
    return (
      <div className="p-6 flex flex-col items-center justify-center">
        <FaSpinner className="animate-spin h-8 w-8 text-blue-500" />
        <p className="mt-2 text-gray-600">Loading form...</p>
      </div>
    );
  }

  const handleSubmit = async ({ formData }, e) => {
    e.preventDefault();
    onSubmit(formData, formId);
  };
  const schema = customSchema || formTemplate?.attributes.schema;
  const uiSchema = customUiSchema || formTemplate?.attributes.ui_schema;
  const formTitle = title || formTemplate?.attributes.name;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
       <Button
        onClick={() => navigate(-1)}
        className="mb-4"
      >
        Back
      </Button>
      <h2 className="text-2xl font-bold mb-6">{formTitle}</h2>
      <SchemaBasedDataGrid inputSchema={schema} uiSchema={uiSchema} mode={mode} />
    </div>
  );
};

FormViewer.propTypes = {
  mode: PropTypes.oneOf(["preview", "edit"]).isRequired, // Define the mode
  onSubmit: PropTypes.func.isRequired, // Function to handle form submission
  customSchema: PropTypes.object.isRequired, // Schema for the form
  customUiSchema: PropTypes.object.isRequired, // UI schema for the form
  formData: PropTypes.object, // Initial form data
  formId: PropTypes.string, // ID of the form_data_detail being edited
};

FormViewer.defaultProps = {
  formData: {},
  formId: null,
};

export default FormViewer;
