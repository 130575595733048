import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminRoutes from './routes/AdminRoutes';
import BrandRoutes from './routes/BrandRoutes';
import SupplierRoutes from './routes/SupplierRoutes';
import Login from './components/auth/Login';
import ResetPassword from './components/auth/ResetPassword';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/brand/*" element={<BrandRoutes />} />
      <Route path="/supplier/*" element={<SupplierRoutes />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default App;
