import React, { useState, useEffect, useRef } from "react";
import { FaPaperPlane, FaTimes, FaRobot, FaSpinner } from "react-icons/fa";
import { MessageCircle } from "lucide-react";
import axios from "axios";

const TypingIndicator = () => (
  <div className="flex space-x-2 p-3 bg-gray-100 rounded-lg max-w-[100px]">
    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
    <div
      className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
      style={{ animationDelay: "0.2s" }}
    ></div>
    <div
      className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
      style={{ animationDelay: "0.4s" }}
    ></div>
  </div>
);

const MessageTimestamp = ({ date }) => (
  <span className="text-xs text-gray-400 mt-1">
    {new Date(date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}
  </span>
);

const Message = ({ message, isLast }) => (

  <div
    className={`flex ${
      message.sender === "user" ? "justify-end" : "justify-start"
    } mb-4 items-end`}
  >
    {message.sender === "bot" && (
      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-2">
        <FaRobot className="text-blue-500 text-sm" />
      </div>
    )}
    <div className="flex flex-col">
      <div
        className={`rounded-2xl px-4 py-2 max-w-[80%] ${
          message.sender === "user"
            ? "bg-blue-500 text-white rounded-br-none"
            : "bg-gray-100 text-gray-800 rounded-bl-none"
        }`}
      >
        {message.text}
      </div>
      <MessageTimestamp date={message.timestamp} />
    </div>
    {message.sender === "user" && (
      <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center ml-2">
        <MessageCircle className="text-white text-sm" />
      </div>
    )}
  </div>
);

const EnhancedChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultMessage = {
    sender: "bot",
    text: "Hello! I'm here to help you input data correctly and handle any errors. Feel free to ask me about validation conditions or any other questions you might have.",
    timestamp: new Date(),
  };

  const [messages, setMessages] = useState([defaultMessage]);

  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const apiClient = axios.create({
    baseURL: "https://pef.crystalchain.io/api",
    auth: {
      username: "amir",
      password: "fd97Laf2jhB$"
    },
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Add request interceptor to handle CORS preflight
  apiClient.interceptors.request.use((config) => {
    // Add additional headers that might be needed for CORS
    config.headers["Access-Control-Request-Method"] = "POST";
    config.headers["Access-Control-Request-Headers"] = "content-type,authorization";
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  });

  // Add response interceptor to handle errors
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error("API Error:", error);
      if (error.code === "ERR_NETWORK" || error.response?.status === 403) {
        return Promise.reject(new Error("CORS Error: Unable to connect to the server. Please ensure the server allows requests from this origin."));
      }
      return Promise.reject(error);
    }
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isOpen]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const simulateTyping = async () => {
    setIsTyping(true);
    await new Promise((resolve) => setTimeout(resolve, 1500 + Math.random() * 1000));
    setIsTyping(false);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = {
      sender: "user",
      text: input.trim(),
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      await simulateTyping();

      const response = await apiClient.post("/virtual-assistant", {
        message: input.trim(),
      });

      const botMessage = {
        sender: "bot",
        text: response.data.reply || response.data.response,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        sender: "bot",
        text: error.message || "Sorry, I encountered an error. Please try again.",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen && (
        <div className="w-96 h-[500px] bg-white rounded-lg shadow-xl flex flex-col overflow-hidden">
          {/* Header */}
          <div className="flex justify-between items-center p-4 bg-gradient-to-r from-blue-500 to-blue-600">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                <FaRobot className="text-white text-xl" />
              </div>
              <div>
                <h2 className="text-white font-semibold">Crystal Assistant</h2>
                <p className="text-blue-100 text-sm">Always here to help</p>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false) && setMessages([])}
              className="text-white/80 hover:text-white focus:outline-none transition-colors"
            >
              <FaTimes />
            </button>
          </div>

          {/* Messages */}
          <div className="flex-1 p-4 overflow-y-auto bg-white">
            {messages.map((msg, index) => (
              <Message
                key={index}
                message={msg}
                isLast={index === messages.length - 1}
              />
            ))}
            {isTyping && (
              <div className="flex justify-start">
                <TypingIndicator />
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Input */}
          <div className="p-4 border-t bg-gray-50">
            <div className="relative">
              <textarea
                ref={inputRef}
                className="w-full p-3 pr-12 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                rows="2"
                placeholder="Type your message..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
              />
              <button
                onClick={handleSendMessage}
                disabled={isLoading || input.trim() === ""}
                className={`absolute right-2 bottom-2 p-2 rounded-full transition-colors ${
                  input.trim() === ""
                    ? "text-gray-400 bg-gray-100"
                    : "text-white bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  <FaPaperPlane className="text-sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Chat Button */}
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="group flex items-center justify-center w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-300"
          aria-label="Open ChatBot"
        >
          <FaRobot
            size={24}
            className="group-hover:scale-110 transition-transform duration-300"
          />
        </button>
      )}
    </div>
  );
};

export default EnhancedChatBot;
